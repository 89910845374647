import { OTHER_OPTION } from '../constants/constants';

export type WhereOptions = 'Home' | 'Away';

export const whereIsGame = (awayTeam, awaySchool, selected_schools) => {
  // Logic to add where field
  // This field uses for merging and also need for partners (Dragonfly and RankOne)
  let where: WhereOptions = 'Home';

  if (selected_schools && awayTeam
    && awayTeam?.value !== OTHER_OPTION.value
    && awaySchool?.value
    && awaySchool?.value === selected_schools[0]?._id
  ) {
    where = 'Away';
  }

  return where;
};
