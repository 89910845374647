import React from 'react';

import { Controller } from 'react-hook-form';
import Button from 'src/components/common/button/CustomButton';
import { SwitchInput } from 'src/components/common/switch-input/SwitchInput';

import { PartnerDetails } from '../additionalInformation/components/partnerDetails/PartnerDetails';
import styles from './Actions.module.css';
import { ActionsProps } from './Actions.types';

const Actions = (props: ActionsProps) => {
  const {
    control,
    partners,
    scheduleId,
    created,
    updated,
    rankoneFacilityId,
    isSuperAdmin,
    isEdit,
    isDisabled,
    isLoading,
    handleReset,
    handleCancel,
    handleSubmit,
  } = props;

  const submitButtonTitleByFormType = isEdit ? 'Save' : 'Add';
  const submitButtonTitle = isLoading ? 'Saving...' : submitButtonTitleByFormType;

  return (
    <div className={styles.actionsContainer}>
      {isEdit && (
        <div className={styles.switchContainer}>

          {isSuperAdmin && (
            <Controller
              name="inactive"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className={styles.switchWrapper}>
                  <div className={styles.switchItem}>
                    <SwitchInput
                      onChange={onChange}
                      checked={value}
                      disabled={isDisabled}
                    />
                  </div>
                  <p className={styles.switchLabel}>Inactive Game</p>
                </div>
              )}
            />
          )}

          <Controller
            name="cancelled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={styles.switchWrapper}>
                <div className={styles.switchItem}>
                  <SwitchInput
                    onChange={onChange}
                    checked={value}
                    disabled={isDisabled}
                  />
                </div>
                <p className={styles.switchLabel}>Cancel Game</p>
              </div>
            )}
          />

          <Controller
            name="postponed"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={styles.switchWrapper}>
                <div className={styles.switchItem}>
                  <SwitchInput
                    onChange={onChange}
                    checked={value}
                    disabled={isDisabled}
                  />
                </div>
                <p className={styles.switchLabel}>Postpone Game</p>
              </div>
            )}
          />
        </div>
      )}

      <div className={styles.actionsWrapper}>

        {!isEdit && (
          <div className={styles.leftWrapper}>
            <Button
              version="transparent"
              className={styles.resetBtn}
              onClick={handleReset}
              disabled={isDisabled}
            >
              Reset
            </Button>
          </div>
        )}

        {isEdit && (
          <>
            <div className={styles.middleWrapper}>
              <div className={styles.infoWrapper}>
                <span className={styles.info}>Schedule ID:</span>
                <span>{scheduleId}</span>
              </div>

              {!!partners?.length && PartnerDetails({ partners, rankoneFacilityId })}
            </div>

            <div className={styles.middleWrapper}>
              <div className={styles.infoWrapper}>
                <p className={styles.info}>Created:</p>
                <span>{created}</span>
              </div>
              <div className={styles.infoWrapper}>
                <p className={styles.info}>Updated:</p>
                <span>{updated}</span>
              </div>
            </div>
          </>
        )}

        <div className={styles.rightWrapper}>
          <Button
            version="transparent"
            className={styles.cancelBtn}
            onClick={handleCancel}
          >
            Exit
          </Button>
          <Button
            version="primary"
            onClick={handleSubmit}
            disabled={isDisabled || isLoading}
          >
            {submitButtonTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Actions;
