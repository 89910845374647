import React from 'react';

import { partnersConfig } from 'src/constants';
import { PartnerEnums } from 'src/enums/PartnerEnums';

import styles from '../../../actions/Actions.module.css';
import { PartnerDetailsProps } from './PartnerDetails.types';

const {
  RANKONE,
} = PartnerEnums;

export const PartnerDetails = (props: PartnerDetailsProps) => {
  const {
    partners,
    rankoneFacilityId,
  } = props;

  // The method for arranging IDs in the required order according to the design.
  // Because the response is arranged in alphabetical order.
  const nonRankOnePartners = partners.map((partner) => {
    if (partner.name !== RANKONE) {
      return (
        <div className={styles.infoWrapper} key={partner.id}>
          <p className={styles.info}>{partnersConfig[partner.name]} ID:</p>
          <span>{partner.id}</span>
        </div>
      );
    }
    return null;
  });

  const rankOnePartner = partners.map((partner) => {
    if (partner.name === RANKONE) {
      return (
        <div key={partner.id} className={styles.facilityWrapper}>
          <div className={styles.infoWrapper}>
            <p className={styles.info}>{partnersConfig[partner.name]} ID:</p>
            <span>{partner.id}</span>
          </div>

          <div className={styles.infoWrapper}>
            <p className={styles.info}>RankOne Facility ID:</p>
            <span>{rankoneFacilityId || '--'}</span>
          </div>
        </div>
      );
    }
    return null;
  });

  return (
    <>
      {nonRankOnePartners}
      {rankOnePartner}
    </>
  );
};
