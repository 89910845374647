import React from 'react';

import EmailIcon from 'src/assets/images/contact/email-icon.svg';
import PhoneIcon from 'src/assets/images/contact/phone-call-icon.svg';
import { ContactEnums } from 'src/enums/ContactEnums';

import styles from './ContactMessageModal.module.css';

const { PHONE_NUMBER, EMAIL } = ContactEnums;

const ContactMessageModal = () => {
  return (
    <div>
      <p className={styles.contactMessage}>Contact Support if unable to complete this process.</p>
      <div className={styles.contactContainer}>
        <div>
          <img src={PhoneIcon} alt="phone-icon" />
          <span><a className={styles.contactConnect} href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></span>
        </div>

        <div>
          <img src={EmailIcon} alt="email-icon" />
          <span><a className={styles.contactConnect} href={`mailto:${EMAIL}`}>{EMAIL}</a></span>
        </div>
      </div>
    </div>
  );
};

export default ContactMessageModal;
