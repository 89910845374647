import {
  fullDate, monthDayYear, monthDayShort, monthDay, hoursMinutes,
} from './dateFormatConfig';
import getCSTTimeZoneFormat from './getCSTTimeZoneFormat';
import { getMonthDayYear } from './getMonthDayYear';
import getWeekdayMonthDay from './getWeekdayMonthDay';

//  Example of date output:
// 'MMM DD, YYYY' => Jun 02, 2022
// 'MMM DD' => Jun 02
// 'MMM D' => Jun 2
// 'HH:MM' => 11:54 AM
// 'YYYY-MM-DD' => 2022-06-02
// 'MM/DD/YYYY hh:mm' => 12/21/2023 11:54 AM
// 'EEE, MMM dd h:mm a' => Sun, Jun 02 11:54 AM
// 'M/D/YYYY hh:mm' => 2/13/2019, 8:58:37 AM
//  No argument => Jun 02, 2022, 11:54 AM (MMM DD, YYYY, HH:MM)

type FormatOptions = 'MMM DD, YYYY' | 'MMM DD' | 'MMM D' | 'HH:MM' | 'YYYY-MM-DD' | 'M/D/YYYY hh:mm' | 'MM/DD/YYYY hh:mm' | 'EEE, MMM dd h:mm a';

const dateFormat = (date: Date | string, format?: FormatOptions) => {
  if (format === 'MMM DD, YYYY') {
    return new Date(date).toLocaleString('en-US', monthDayYear);
  }
  if (format === 'MMM DD') {
    return new Date(date).toLocaleString('en-US', monthDay);
  }
  if (format === 'MMM D') {
    return new Date(date).toLocaleString('en-US', monthDayShort);
  }
  if (format === 'HH:MM') {
    return new Date(date).toLocaleString('en-US', hoursMinutes);
  }
  if (format === 'YYYY-MM-DD') {
    return new Date(date).toLocaleDateString('fr-CA');
  }
  if (format === 'M/D/YYYY hh:mm') {
    return getCSTTimeZoneFormat(new Date(date));
  }
  if (format === 'MM/DD/YYYY hh:mm') {
    return getMonthDayYear(new Date(date));
  }
  if (format === 'EEE, MMM dd h:mm a') {
    return getWeekdayMonthDay(new Date(date));
  }

  return new Date(date).toLocaleString('en-US', fullDate);
};

export default dateFormat;
