import React from 'react';

import { Controller } from 'react-hook-form';
import { Input } from 'src/components/common/input/Input';
import { SelectAsyncPaginate } from 'src/components/common/select-async/SelectAsyncPaginate';
import { SelectInput } from 'src/components/common/select/Select';

import styles from './AdditionalInformation.module.css';
import { AdditionalInformationProps } from './AdditionalInformation.types';

const AdditionalInformation = (props: AdditionalInformationProps) => {
  const {
    control,
    isSuperAdmin,
    isAssociationAdmin,
    isDisabledFacility,
    isDisabled,
    tournaments,
    isFacilityOptionsLoading,
    handleFacilityChange,
    handleLoadFacilities,
  } = props;

  return (
    <div className={styles.scheduleFormColumn}>
      <h6 className={styles.columnTitle}>Additional Information</h6>

      <Controller
        control={control}
        name="facility"
        render={({ field: { value, onChange } }) => (
          <SelectAsyncPaginate
            label="Facility"
            placeholder="Select Facility"
            value={value}
            onChange={(selected) => handleFacilityChange(selected, onChange)}
            loadOptions={handleLoadFacilities}
            isLoading={isFacilityOptionsLoading}
            isDisabled={isDisabledFacility}
            menuPlacement="bottom"
          />
        )}
      />

      <Controller
        control={control}
        name="liveStreamURL"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            type="text"
            label="Live Stream URL"
            placeholder="Enter URL (Optional)"
            value={value}
            onChange={onChange}
            errorMsg={error?.message}
            disabled={isDisabled}
          />
        )}
      />

      {(isSuperAdmin || isAssociationAdmin) && (
        <Controller
          control={control}
          name="tournament"
          render={({ field: { value, onChange } }) => (
            <SelectInput
              label="Tournament"
              placeholder="Select Tournament (Optional)"
              options={tournaments}
              value={value}
              onChange={onChange}
              isDisabled={isDisabled}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="description"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            type="text"
            label="Description"
            placeholder="Please Enter Description"
            value={value}
            onChange={onChange}
            textArea
            errorMsg={error?.message}
            disabled={isDisabled}
          />
        )}
      />
    </div>
  );
};

export default AdditionalInformation;
