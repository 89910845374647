import React from 'react';

import { ColorModalEnums } from 'src/enums/ColorModalEnums';
import { StatusEnums } from 'src/enums/StatusEnums';

import ContactMessageModal from '../components/contactMessageModal/ContactMessageModal';
import PartnersMessageModal from '../components/partnersMessageModal/PartnersMessageModal';
import { OTHER_OPTION } from '../constants/constants';
import initialConfirmationModalState from '../initialStates/initialConfirmationModalState';
import { ValidateOnSubmitProps, ShowConfirmationModalProps } from '../RegularScheduleForm.types';

const { RED } = ColorModalEnums;
const { SUCCESS, ERROR } = StatusEnums;

const validateOnSubmit = async (values: ValidateOnSubmitProps) => {
  const {
    isSuperAdmin,
    isBroadcaster,
    isAssociationAdmin,
    currentUserSelectedSchools,
    facilityAssignedSchool,
    confirmationModal,
    setConfirmationModal,
    homeSchool,
    awaySchool,
    homeTeam,
    awayTeam,
    partners,
  } = values;

  const isSuperAdminOrBroadcaster = isSuperAdmin || isBroadcaster;
  const isAssignedFacility = currentUserSelectedSchools.includes(facilityAssignedSchool);
  const isSchoolInList = (school: string) => currentUserSelectedSchools.includes(school);
  const isAssignedSchoolOrFacility = currentUserSelectedSchools.find((s: string) => (s === facilityAssignedSchool)
    || (s === homeSchool?.value) || (s === awaySchool?.value));

  const showConfirmationModal = (props: ShowConfirmationModalProps) => {
    const {
      title,
      message,
      approveFn = () => setConfirmationModal(initialConfirmationModalState),
      closeFn = () => setConfirmationModal(initialConfirmationModalState),
      cancelFn = () => setConfirmationModal(initialConfirmationModalState),
      approveTitle,
      cancelTitle = '',
      hideCancelBtn = true,
    } = props;

    setConfirmationModal({
      ...confirmationModal,
      open: true,
      type: RED,
      title,
      message,
      approveFn,
      closeFn,
      cancelFn,
      approveTitle,
      cancelTitle,
      hideCancelBtn,
    });
  };

  if ((!isSuperAdminOrBroadcaster && !isAssignedSchoolOrFacility)) {
    showConfirmationModal({
      title: 'Attention!',
      message: 'To create a schedule, please select either the school under your management or a facility assigned to a school that you oversee.',
      approveTitle: 'Ok',
    });
    return ERROR;
  }

  if (!isSuperAdminOrBroadcaster && !isAssignedFacility) {
    const isHomeSchoolFromList = isSchoolInList(homeSchool?.value);
    const isAwaySchoolFromList = isSchoolInList(awaySchool?.value);

    const isBothOther = (homeTeam?.value === OTHER_OPTION.value) && (awayTeam?.value === OTHER_OPTION.value);
    const isHomeOtherOrMissing = (isHomeSchoolFromList && (homeTeam?.value === OTHER_OPTION.value || !homeTeam?.value) && (awayTeam?.value === OTHER_OPTION.value || !awayTeam?.value))
      || (isHomeSchoolFromList && (homeTeam?.value === OTHER_OPTION.value || !homeTeam?.value));
    const isAwayOtherOrMissing = (isAwaySchoolFromList && (awayTeam?.value === OTHER_OPTION.value || !awayTeam?.value) && (homeTeam?.value === OTHER_OPTION.value || !homeTeam?.value))
      || (isAwaySchoolFromList && (awayTeam?.value === OTHER_OPTION.value || !awayTeam?.value));

    if (isBothOther || isHomeOtherOrMissing || isAwayOtherOrMissing) {
      showConfirmationModal({
        title: 'Please, select the team assigned to\n your school.',
        message: <ContactMessageModal />,
        approveTitle: 'Got it',
      });
      return ERROR;
    }
  }

  if (partners?.length && !isSuperAdmin && !isAssociationAdmin) {
    const result = await new Promise((resolve) => {
      showConfirmationModal({
        title: 'Attention!',
        message: <PartnersMessageModal partnersArray={partners} />,
        approveFn: () => {
          setInterval(() => {
            resolve(SUCCESS);
            setConfirmationModal(initialConfirmationModalState);
          }, 500);
        },
        approveTitle: 'Okay, Save',
        cancelTitle: 'Cancel',
        hideCancelBtn: false,
      });
    });

    // Handle the result after the user's action
    if (result === SUCCESS) {
      return SUCCESS;
    }
    return ERROR;
  }

  return SUCCESS;
};

export default validateOnSubmit;
