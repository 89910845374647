const initialAddTeamModalState = {
  type: null,
  open: false,
  country: null,
  stateId: null,
  schoolId: null,
  sportId: null,
  genderLabel: null,
  levelId: null,
};

export default initialAddTeamModalState;
