/* eslint-disable */
import axiosInstance from 'axios';
import { Service } from 'axios-middleware';

import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { getIdToken } from 'src/util/getIdToken';

// -----------------------------------------------------------------------------
const moment = require('moment');

// Create instance and default config options
const createAxios = () => {
  return axiosInstance.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const axios = createAxios();
axios.interceptors.request.use(async (config) => {
  await getIdToken().then((idToken) => {
    if (idToken !== null) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
  });

  return config;
});

const apiUrl = `/${process.env.REACT_APP_OLDAPI_VERSION}/`;
const apiUrlv4 = `/${process.env.REACT_APP_NEWAPI_VERSION}/`;
const service = new Service(axios);
const { CancelToken } = axiosInstance;
let ServiceInstance = null;
let cancel = () => {
  console.warn('CancelCall is empty');
};

service.register({
  onRequest(config) {
    if (
      config.url.match(`${apiUrl}auth/`)
      // || config.url.match(`${apiUrl}authNew/`)
      // || config.url.match(`${apiUrlv4}auth/`)
      || config.url.match(`${apiUrl}sport`)
      || config.url.match(`${apiUrl}deviceOrders`)
      || config.url.match(`${apiUrl}facilityType`)
      || config.url.match(`${apiUrl}scoreboard`)
      || config.url.match(`${apiUrl}school`)
      || config.url.match(`${apiUrlv4}school`)
      || config.url.match(`${apiUrl}schedule`)
      || config.url.match(`${apiUrlv4}schedule`)
      || config.url.match(`${apiUrlv4}game`)
      || config.url.match(`${apiUrl}game`)
      || config.url.match(`${apiUrl}team`)
      || config.url.match(`${apiUrl}device`)
      || config.url.match(`${apiUrl}shadow`)
      || config.url.match(`${apiUrl}twitter`)
      || config.url.match(`${apiUrl}team_grades`)
      || config.url.match(`${apiUrl}facilityType`)
      || config.url.match(`${apiUrl}facility`)
      || config.url.match(`${apiUrl}flaggedGame`)
      || config.url.match(`${apiUrl}manuallyFedScores`)
      || config.url.match(`${apiUrl}user`)
      || config.url.match(`${apiUrl}notifications`)
      || config.url.match(`${apiUrlv4}team`)
      || (config.url.match(`${apiUrl}user`) && !config.url.match(`${apiUrl}userRole`))
      || config.url.match(`${apiUrl}pitch_appearance`)
      || config.url.match(`${apiUrlv4}associations`)
    ) {
      // add token check only after auth
      // if (config.headers.Authorization) {
      //   const decoded = decode(config.headers.Authorization);
      //   // If the token is expired, the user will be logged out
      //   if (decoded.exp < Date.now() / 1000) {
      //     snackbarService.error('Your session has expired. Please sign in again.');
      //     UserLogOut();
      //   }
      // }
    }
    return config;
  },
  onSync(promise) {
    return promise;
  },
  onResponse(response) {
    return response;
  },

  onResponseError(error) {
    // const data = JSON.parse(error?.response?.data);
    // if ((error?.response.status === 403) && (data.message === 'Authentication required')) {
    //   UserLogOut();
    // }
    throw error;
  },
});

class ScorebirdService {
  hostname = window && window.location && window.location.hostname;

  groupsPromise = null;

  devicesPromise = null;

  teamsPromise = null;

  gamesPromise = {};

  schoolsPromise = null;

  schoolsByStatePromise = {};

  // schoolsAndTeamsByStatePromise = {}
  allFacilitiesPromise = null;

  devicesData;

  groupsData;

  teamsData;

  gamesData = {};

  devicesById = {};

  groupsById = {};

  teamsById = {};

  gamesById = {};

  gamesActiveStartDate = '';

  gamesActiveEndDate = '';

  userDataByEmailPromise = {};

  constructor() {
    // this.axiosCancelSource = axios.CancelToken.source()
    if (!ServiceInstance) {
      ServiceInstance = this;
    }
    return ServiceInstance;
  }

  cancelCall() {
    cancel();
  }

  getSchoolsByState(params) {
    return axios
      .get(`${apiUrl}school/getStateSchools/${params}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get schools by state. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get schools by state:', error);
        snackbarService.error("Can't get schools by state. Please, try later.");
        throw error;
      });
  }

  getSchoolListByState(data) {
    const { state } = data;
    if (!this.schoolsByStatePromise[state]) {
      this.schoolsByStatePromise[state] = axios
        .get(`${apiUrl}school/getStateSchools/${state}`)
        .then((res) => {
          if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
            snackbarService.error("Can't get schools by state. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        })
        .catch((error) => {
          console.error('Error in get schools by state:', error);
          snackbarService.error("Can't get schools by state. Please, try later.");
          throw error;
        });
    }
    return this.schoolsByStatePromise[state];
  }

  getSchoolsByuser(params) {
    return axios
      .post(`${apiUrl}school/getSchoolsByuser/`, params, {
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get schools by user. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        if (axiosInstance.isCancel(err)) {
          return { cancel: true };
        }
        console.error('Error in get schools by user:', err);
        snackbarService.error("Can't get schools by user. Please, try later.");
        throw { success: false, message: err };
      });
  }

  getTeamsPaginated(params) {
    return axios
      .post(`${apiUrl}team/getAll`, params, {
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get all paginated teams. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        if (axiosInstance.isCancel(err)) {
          return { cancel: true };
        }
        console.error('Error in get all paginated teams:', err);
        snackbarService.error("Can't get all paginated teams. Please, try later.");
        throw { success: false, message: err };
      });
  }


  // delete with the RegularScheduleFormOld
  getFacilitiesPaginated(params) {
    return axios
      .post(`${apiUrl}facility/getAll/`, params, {
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get all paginated facilities. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        if (axiosInstance.isCancel(err)) {
          return { cancel: true };
        }
        console.error('Error in get all paginated facilities:', err);
        snackbarService.error("Can't get all paginated facilities. Please, try later.");
        throw { success: false, message: err };
      });
  }

  getUsersPaginated(params) {
    return axios
      .post(`${apiUrl}user/getAll`, params, {
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error("Can't get all paginated users. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        if (axiosInstance.isCancel(err)) {
          return { cancel: true };
        }
        console.error('Error in get all paginated users:', err);
        snackbarService.error("Can't get all paginated users. Please, try later.");
        throw { success: false, message: err };
      });
  }

  getFacilities() {
    if (!this.allFacilitiesPromise) {
      this.allFacilitiesPromise = axios
        .get(`${apiUrl}facility/allfacilities/`)
        .then((res) => {
          if (!res.data.success) {
            snackbarService.error("Can't get facilities. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        })
        .catch((error) => {
          console.error('Error in get facilities:', error);
          snackbarService.error("Can't get facilities. Please, try later.");
          throw error;
        });
    }
    return this.allFacilitiesPromise;
  }

  getSchedulesByTeam(teamId) {
    return axios
      .get(`${apiUrl}schedule/getTeamSchedules/${teamId}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get schedules by team. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get schedules by team:', error);
        snackbarService.error("Can't get schedules by team. Please, try later.");
        throw error;
      });
  }

  getSchedulesById(params) {
    return axios
      .post(`${apiUrl}schedule/getSchedules/`, params)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error("Can't get schedules. Please, try later.");
          console.error('Error in get schedules by id:', res.message);
          throw res.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get schedules by id:', error);
        snackbarService.error("Can't get schedules. Please, try later.");
        throw error;
      });
  }

  getUsers() {
    return axios
      .get(`${apiUrl}user/getAllUsers`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get users:', error);
          throw res.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error when try to get users:', error);
        snackbarService.error('Something went wrong. Please, try later.');
        throw error;
      });
  }

  getFacilityBySchoolId(params) {
    return axios
      .post(`${apiUrl}school/facility/`, params)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error("Can't get facilities. Please, try later.");
          throw res.data.message;
        }
        return res.data.data;
      })
      .catch((error) => {
        console.error('Error in get facilities by school id:', error);
        snackbarService.error("Can't get facilities. Please, try later.");
        throw error;
      });
  }

  getSchoolData(schoolId) {
    return axios
      .get(`${apiUrl}school/${schoolId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get school data by id:', error);
        if (error.response.status !== 422) {
          snackbarService.error("Can't get school details. Please, try later.");
        }
        throw error;
      });
  }

  getSchoolTweets(params) {
    return axios
      .post(`${apiUrlv4}twitter/get-school-tweets`, params)
      .then((res) => {
        // didn't check response, mb need to do corrections
        if (!res.data.success) {
          snackbarService.error("Can't get get school tweets. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get school tweets:', error);
        snackbarService.error("Can't get school tweets. Please, try later.");
        throw error;
      });
  }

    twitterAuthStatusCheck(params) {
        return axios
            .post(`${apiUrlv4}twitter/auth-status-check/`, params)
            .then((res) => {
                if (!res.data.success) {
                    snackbarService.error("Can't get twitter auth status. Please, try later.");
                    throw res.data.message;
                }
                return res.data;
            })
            .catch((error) => {
                console.error('Error in get twitter auth status:', error);
                snackbarService.error("Can't get twitter auth status. Please, try later.");
                throw error;
            });
    }

  getUserData(userId) {
    return axios
      .get(`${apiUrl}user/getUserData/${userId}`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error("Can't get user details. Please, try later.");
          throw res.message;
        }
        return res.data;
      })
      .catch((error) => {
        if (error.response.status !== 422) {
          snackbarService.error('Something went wrong. Please, try later.');
        }
        console.error('Error in get user data by id:', error);
        throw error;
      });
  }

  getUserDataByEmail(email, isRefetch) {
    if (!this.userDataByEmailPromise[email] || isRefetch) {
      this.userDataByEmailPromise[email] = axios
        .get(`${apiUrlv4}user/data/${email}`)
        .then((res) => {
          if (!res.data.success) {
            snackbarService.error("Can't get user details. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        })
        .catch((error) => {
          console.error('Error in get user data by email:', error);
          snackbarService.error("Can't get user details. Please, try later.");
          throw error;
        });
    }
    return this.userDataByEmailPromise[email];
  }

  getUser(userId) {
    return axios
      .get(`${apiUrl}user/getUserData/${userId}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get user details. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        if (error.response.status !== 422) {
          snackbarService.error('Something went wrong. Please, try later.');
        }
        console.error('Error in get user by id:', error);
        throw error;
      });
  }

  addFacility(params) {
    return axios
      .post(`${apiUrl}facility/`, params)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't create new facility. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in creating new facility:', error);
        snackbarService.error("Can't create new facility. Please, try later.");
        throw error;
      });
  }

  saveFacilityDetails(params) {
    return axios
      .put(`${apiUrl}facility/${params[0]._id}`, params[0])
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't save changes in facility. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in saving facility details:', error);
        snackbarService.error("Can't save changes in facility. Please, try later.");
        throw error;
      });
  }

  saveSchoolAddress(params) {
    return axios
      .put(`${apiUrl}school/${params[0]._id}`, params[0])
      .then((res) => {
        this.schoolsPromise = null;
        if (!res.data.success) {
          snackbarService.error("Can't save school changes. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in saving school details:', error);
        snackbarService.error("Can't save school changes. Please, try later.");
        throw { success: false, err: error };
      });
  }

  adduserFromUserpageNew(params) {
    return axios
      .post(`${apiUrl}user/adduserNew`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error('Error in add new user:', error);
        snackbarService.error("Can't create new user. Please, try later.");
        throw error;
      });
  }

  setUserSchoolsAndTeams(params) {
    return axios
      .post(`${apiUrlv4}auth/selectSchoolsTeamsAndSignIn`, params)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error("Can't save data. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error saved schools and teams for user:', error);
        throw error;
      });
  }

  getUserProfileReport(params) {
    return axios
      .post(`${apiUrl}user/getUserProfileReport`, params)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error get user profile data for dashboard:', error);
        throw error;
      });
  }

  getTeamData(teamId) {
    return axios
      .get(`${apiUrl}team/detail/${teamId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get team data by id:', error);
        if (error.response.status !== 422) {
          snackbarService.error("Can't get team details. Please, try later.");
        }
        throw error;
      });
  }

  updateTeamInfo(params) {
    return axios
      .put(`${apiUrl}team/${params._id}`, params)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't save changes. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error("Can't save changes. Please, try later.");
        console.error('Error when saving changes in team:', error);
        throw error;
      });
  }

  addTeam(params) {
    return axios
      .post(`${apiUrl}team`, params)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't save changes. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error("Can't save changes. Please, try later.");
        console.error('Error when creating new team:', error);
        throw error;
      });
  }

  getShortTeamsDataBySchoolIds(school_ids) {
    return axios
      .post(`${apiUrlv4}team/getTeamsBySchools`, school_ids)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get teams. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error("Can't get teams. Please, try later.");
        console.error('Error get teams by school ids:', error);
        throw error;
      });
  }

  getUsersBySchoolId(schoolId) {
    return axios
      .get(`${apiUrl}user/schoolUsers/${schoolId}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error get users by school id:', error);
        throw error;
      });
  }

  getAllSports() {
    return axios
      .get(`${apiUrl}sport`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error getting sports list:', error);
        throw error;
      });
  }

  getTeamGrades() {
    return axios
      .get(`${apiUrl}team_grades`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error getting grades list:', error);
        throw error;
      });
  }

  // TODO !!
  //* * Unused method */
  // Uses only in old schedules list
  getSchedules(params) {
    return axios.post(`${apiUrl}schedule/getSchoolSchedules`, params).then((res) => res.data);
  }

  signInNew(params) {
    return axios
      .post(`${apiUrl}authNew/signInNew`, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error('Error with sign in:', error);
        throw error;
      });
  }

  addScheduleGame(params) {
    return axios
      .post(`${apiUrl}schedule/addSchedule`, params)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when creating new schedule game:', error);
        throw error;
      });
  }

  updateScheduleQuick(params) {
    return axios
      .put(`${apiUrl}/schedule/updateScheduleQuick/${params.schedule_id}`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when quick schedule update:', error);
        throw error;
      });
  }

  // This method is copy of updateScheduleGame updated for Games feature needs,
  // is simple to "updateScheduleGame" with some improvements
  // Old request not deleted because it used in another pages
  updateGame(game) {
    return axios
      .put(`${apiUrlv4}schedule/${game._id}`, game)
      .then((res) => {
      const {
        _id,
        name,
        mapping,
        team_away_school,
        team_home_school,
        facility_mongo_id,
        schedule_id,
        last_update_ts
      } = res.data.updatedSchedule;
        const game = {
          _id,
          name,
          mapping,
          team_away_school,
          team_home_school,
          facility_mongo_id,
          schedule_id,
          last_update_ts,
        };
        return { data: game, success: res.success };
      })
      .catch((error) => {
        console.error('Error when updating game:', error);
        throw error;
      });
  }

  updateScheduleGame(params) {
    return axios
      .put(`${apiUrlv4}schedule/${params[0]._id}`, params[0])
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when updating game:', error);
        throw error;
      });
  }

  getHomeAwayTeamRosters(params) {
    return axios
      .post(`${apiUrl}roster/getHomeAwayTeamRosters`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try get Home and Away team Rosters:', error);
        throw error;
      });
  }

  getPitchSchedule(scheduleId) {
    return axios
      .get(`${apiUrl}schedule/getPitchSchedule/${scheduleId}`)
      .then((res) => {
        return res.data.data[0];
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try get pitch schedules:', error);
        throw error;
      });
  }

  deleteScheduleGame(id) {
    return axios
      .delete(`${apiUrl}schedule/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error('Error when try to delete game:', error);
        throw error;
      });
  }

  getDevicesBySchool(schoolId) {
    return axios
      .get(`${apiUrl}device/getDeviceBySchool/${schoolId}`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to get devices by school:', res.message);
          snackbarService.error('Something went wrong. Please, try later.');
          throw res;
        }
        return res;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get devices by school:', error);
        throw error;
      });
  }

  getAvailableDevices() {
    return axios
      .get(`${apiUrl}device/getAvailableDevices/`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get available devices:', error);
        throw error;
      });
  }

  getDeviceDetails(deviceId) {
    return axios
      .get(`${apiUrl}device/detail/${deviceId}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        if (error.response.status !== 422) {
          snackbarService.error(`Device ${deviceId} is not responding. Please, try again later.`);
        }
        console.error('Error when try to get device details:', error);
        throw error;
      });
  }

  getDeviceStatus(params) {
    return axios
      .get(`${apiUrl}device/getStatus/${params.serial}`)
      .then((res) => {
        // don't catch success false, as for now it's being processed in component
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get device status:', error);
        throw error;
      });
  }

  getDevicePastGames(params) {
    const timzone_offset = new Date().getTimezoneOffset();
    Object.assign(params, { offset: timzone_offset });
    return axios
      .post(`${apiUrl}game/getGamesOfDevice/`, params, {
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => {
        if (!res.data.data) {
          const games = [];
          return {
            data: games,
            success: true,
            page: res.data.page,
            totalCount: res.data.totalCount,
          };
        }
        const games = res.data.data;
        const gamesData = [];
        games.forEach((game) => {
          const partner_id = game.game_data && game.game_data.state ? game.game_data.state.partner_id : null;
          const game_icon = game.game_icon ? game.game_icon : 'no-icon';
          const device_name = game.game_data && game.game_data.device_serial ? game.game_data.device_serial : '--';
          const start_time = game.game_start_ts;
          const game_data = {
            ...game,
            _id: game._id,
            game_id: game._id || '--',
            schedule_id: game.schedule_id || '--',
            game_name: game.name || '--',
            start_time: start_time || '--',
            game_start_ts: start_time || '--',
            group_name: game.group_name || '--',
            home_team: game.home_name || game.team_home_school || '--',
            team_away_name: game.team_away_data ? game.team_away_data.name : '- -',
            team_home_name: game.team_home_data ? game.team_home_data.name : '- -',
            home_team_id: game.team_home_id || '--',
            away_team: game.away_name || game.team_away_school || '--',
            away_team_id: game.team_away_id || '--',
            facility: game.facility_data ? game.facility_data.name : '--',
            sport: game.sport ? game.sport : game.sport_data ? game.sport_data.name : '--',
            device_name,
            game_snapshot: game.game_snapshot || '--',
            game_icon,
            game_status: game.game_status,
            finalized: game.finalized && game.finalized === true,
            game_data: game.game_data || '',
            partner_id,
          };
          gamesData.push(game_data);
        });
        this.gamesData[params.date] = gamesData;
        return {
          data: gamesData,
          page: res.data.page,
          totalCount: res.data.totalCount,
          success: true,
        };
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get games by device:', err);
        throw axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  // Need to change to the new one request to get games in devices module, then it will be unused
  getGamesDataByDateandSchool(params) {
    const timzone_offset = new Date().getTimezoneOffset();
    Object.assign(params, { offset: timzone_offset });
    return axios
      .post(`${apiUrl}game/getGamesByschool/`, params, {
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => {
        if (!res.data.data) {
          const games = [];
          return {
            data: games,
            success: true,
            page: res.data.page,
            totalCount: res.data.totalCount,
          };
        }
        const games = res.data.data;
        const gamesData = [];
        const getReported = (game) => {
          if (game.scores_posted && game.scores_posted.length) {
            return game.scores_posted.map((score) => score.partner).join(',');
          }
          return '--';
        };
        games.forEach((game) => {
          const partner_id = game.game_data && game.game_data.state ? game.game_data.state.partner_id : null;
          const game_icon = game.game_icon ? game.game_icon : 'no-icon';
          const device_name = game.game_data && game.game_data.device_serial ? game.game_data.device_serial : '--';
          const start_time = game.game_start_ts;
          const game_data = {
            ...game,
            reported: getReported(game),
            _id: game._id,
            game_id: game._id || '--',
            schedule_id: game.schedule_id || '--',
            game_name: game.name || '--',
            start_time: start_time || '--',
            game_start_ts: start_time || '--',
            group_name: game.group_name || '--',
            home_team: game.home_name || game.team_home_school || '--',
            team_away_name: game.team_away_data ? game.team_away_data.name : '- -',
            team_home_name: game.team_home_data ? game.team_home_data.name : '- -',
            home_team_id: game.team_home_id || '--',
            away_team: game.away_name || game.team_away_school || '--',
            away_team_id: game.team_away_id || '--',
            facility: game.facility_data ? game.facility_data.name : '--',
            sport: game.sport ? game.sport : game.sport_data ? game.sport_data.name : '--',
            device_name,
            game_snapshot: game.game_snapshot || '--',
            game_icon,
            game_status: game.game_status,
            finalized: game.finalized && game.finalized === true,
            game_data: game.game_data || '',
            partner_id,
            confirmed_by: game.confirmed_by || '--',
          };
          gamesData.push(game_data);
        });
        this.gamesData[params.date] = gamesData;
        return {
          data: gamesData,
          page: res.data.page,
          totalCount: res.data.totalCount,
          success: true,
        };
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get games:', error);
        throw axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  getAllTeams() {
    return axios
      .get(`${apiUrl}team/`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to get teams:', res.message);
          snackbarService.error('Something went wrong. Please, try later.');
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get teams:', err);
        throw axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  assignTeams(params) {
    return axios
      .put(`${apiUrl}device/assignTeams/${params.serial}`, params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to assign team to device:', err);
        throw axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  assignTeamsToFacility(params) {
    const { facilityId } = params;
    return axios
      .put(`${apiUrl}facility/assignTeams/${facilityId}`, params)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to assign teams to the facility:', res.message);
          snackbarService.error('Something went wrong. Please, try later.');
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        console.error('Error when try to assign teams to the facility:', err);
        throw err;
      });
  }

  //* * UNUSED METHOD */
  // getGamesData(selected_date) {
  //   let timzone_offset = new Date().getTimezoneOffset();
  //   return axios.get(`${apiUrl}game/getGamesByDate/${selected_date}/${timzone_offset}`);
  // }

  getGameDetails(scheduleId) {
    const isDev = window.location.hash === '#/calendar-dev';
    const apiFolder = isDev ? 'getGameDetailDev' : 'getGameDetail';
    return axios
      .get(`${apiUrl}game/${apiFolder}/${scheduleId}`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to get game details:', res.message);
          snackbarService.error('Something went wrong. Please, try later.');
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get game details:', err);
        throw err;
      });
  }

  updateSport(id, payload) {
    return axios
      .put(`${apiUrl}sport/${id}`, payload)
      .then((res) => {
        if (!res.success) {
          console.error('Error when try to update sport:', res.message);
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to update sport:', err);
        throw err;
      });
  }

  addSport(payload) {
    return axios
      .post(`${apiUrl}sport`, payload)
      .then((res) => {
        if (!res.success) {
          console.error('Error when try to add sport:', res.message);
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to add sport:', err);
        throw err;
      });
  }

  //* * UNUSED METHOD */
  // getDashboardAnalytics() {
  //   return axios.get(`${apiUrl}device/chatCount`).then((res) => {
  //     return res.data;
  //   });
  // }

  // PROPS:
  //   - selected_states (array) e.g. ['TX', 'CA']
  //   - super_admin (boolean) e.g. true //hide inactive games in report
  //   - isCustomer (boolean) e.g. false //display schools only labeled as isCustomer
  //   - offset (number) e.g. -180 //offset for date time
  //   - school_id (string) e.g. '5e8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f' //priority under states
  //   - type (string) e.g.
  //   - latest (boolean) e.g. false //show latest games

  getCountForDashboardWidgets(params) {
    Object.assign(params, { offset: new Date().getTimezoneOffset() });
    return axios
      .post(`${apiUrl}device/dashboardCount`, params)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to get count for dashboard widgets:', res.message);
          snackbarService.error("Something went wrong. Can't get count for dashboard.");
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error("Something went wrong. Can't get count for dashboard.");
        console.error('Error when try to get count for dashboard widgets:', err);
        throw err;
      });
  }

  getNewUsersPaginated(params) {
    return axios
      .post(`${apiUrl}user/getNewUsers`, params, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then((response) => response.data)
      .catch((err) => {
        snackbarService.error("Something went wrong. Can't get users.");
        console.error('Error when try to get users:', err);
        return axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  getFanAppUsersPaginated(params) {
    return axios
      .post(`${apiUrl}user/getAllFanAppUsers`, params, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then((response) => response.data)
      .catch((err) => {
        snackbarService.error("Something went wrong. Can't get users.");
        console.error('Error when try to get fan app users:', err);
        return axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  verifyUser(params) {
    return axios
      .put(`${apiUrl}user/verifyUser/${params._id}`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get verify user:', err);
        throw err;
      });
  }

  resendActivation(params) {
    return axios
      .post(`${apiUrl}user/resendActivation/`, params)
      .then((res) => {
        // didn't add a res.success check because there is processing in the component
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to resend activation:', err);
        throw err;
      });
  }

  changeUserPasswordByAdmin(payload) {
    return axios
      .put(`${apiUrl}user/changeUserPasswordByAdmin`, payload)
      .then((res) => {
        // didn't add a res.success check because there is processing in the component
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to change password:', err);
        throw err;
      });
  }

  deleteUser(email) {
    return axios
      .delete(`${apiUrl}user/deleteUser/${email}`)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to delete user:', err);
        throw err;
      });
  }

  declineUser(email) {
    return axios
      .put(`${apiUrl}user/declineUser/${email}`)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to decline user:', err);
        throw err;
      });
  }

  gameFinalize(schedule_id) {
    return axios
      .get(`${apiUrl}game/gameFinalize/${schedule_id}/true`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to finalize game:', res.message);
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to finalize game:', err);
        throw err;
      });
  }

  // addFeedUrl(params) {
  //   return axios
  //     .post(`${apiUrl}rssFeeds/addFeedUrls`, params)
  //     .then((res) => {
  //       // didn't add a res.success check because there is processing in the component
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       snackbarService.error("Something went wrong. Please, try later.");
  //       console.error("Error when try to add feed url:", err);
  //       throw err;
  //     });
  // }

  // getFeedUrls() {
  //   return axios
  //     .get(`${apiUrl}rssFeeds/`)
  //     .then((res) => {
  //       if (!res.success) {
  //         snackbarService.error("Something went wrong. Please, try later.");
  //         console.error("Error when try to get feed urls:", res.message);
  //         throw res;
  //       }
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       snackbarService.error("Something went wrong. Please, try later.");
  //       console.error("Error when try to get feed urls:", err);
  //       throw err;
  //     });
  // }

  // getFeedUrlsBySchool(schoolId) {
  //   return axios
  //     .get(`${apiUrl}rssFeeds/${schoolId}`)
  //     .then((res) => {
  //       if (!res.success) {
  //         snackbarService.error("Something went wrong. Please, try later.");
  //         console.error("Error when try to get feed urls by school:", res.message);
  //         throw res;
  //       }
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       snackbarService.error("Something went wrong. Please, try later.");
  //       console.error("Error when try to get feed urls by school:", err);
  //       throw err;
  //     });
  // }

  // removeFeedRecord(schoolId) {
  //   return axios
  //     .delete(`${apiUrl}rssFeeds/deleteFeedUrl/${schoolId}`)
  //     .then((res) => {
  //       if (!res.success) {
  //         snackbarService.error("Something went wrong. Please, try later.");
  //         console.error("Error when try to remove feed record:", res.message);
  //         throw res;
  //       }
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       snackbarService.error("Something went wrong. Please, try later.");
  //       console.error("Error when try to remove feed record:", err);
  //       throw err;
  //     });
  // }

  getLivescores(params) {
    Object.assign(params, { offset: new Date().getTimezoneOffset() });
    return axios
      .post(`${apiUrl}liveGame/websubscribedGames`, params)
      .then((res) => {
        if (!res.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get live scores:', res.message);
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get live scores:', err);
        throw err;
      });
  }

  getShadowData(device_id) {
    return axios
      .get(`${apiUrl}device/getShadowData/${device_id}`)
      .then((res) => {
        if (res.data) {
          return res.data;
        }
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get shadow data:', res.message);
        throw res;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get shadow data:', err);
        throw err;
      });
  }

  getLiveGameState(schedule_id) {
    return axios
      .get(`${apiUrl}liveGame/getLiveGameState/${schedule_id}`)
      .then((response) => {
        if (response.data.message) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get live game state:', res.message);
          throw res;
        }
        return response.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get live game state:', err);
        throw err;
      });
  }

  getStreamingData(device_id) {
    return axios
      .get(`${apiUrl}device/testDevice/${device_id}`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to get streaming data:', res.message);
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get streaming data:', err);
        throw err;
      });
  }

  addShadow(params) {
    return axios
      .post(`${apiUrl}shadow/`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to add shadow:', err);
        throw err;
      });
  }

  getAllShadows() {
    return axios
      .get(`${apiUrl}shadow/`)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res.data.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get all shadows:', err);
        throw err;
      });
  }

  updateShadow(params) {
    return axios
      .put(`${apiUrl}shadow/${params._id}`, params)
      .then((res) => {
        // didn't add a res.success check because there is processing in the component
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to update shadow:', err);
        throw err;
      });
  }

  fanappUserMgmtUser(convertUserParams) {
    return axios
      .post(`${apiUrl}user/fanappUserMgmtUser/`, convertUserParams)
      .then((res) => {
        // didn't add a res.success check because there is processing in the component
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error in request fanappUserMgmtUser:', err);
        throw err;
      });
  }

  shadowData(params) {
    return axios
      .get(`${apiUrl}device/getShadowData/${params.serial}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get shadow data:', err);
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get shadow data:', err);
        throw err;
      });
  }

  getPromoCodes() {
    return axios
      .get(`${apiUrl}deviceOrders/getAllCoupons`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get promocodes:', err);
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get promocodes:', err);
        throw err;
      });
  }

  createDeviceOrder(params) {
    return axios
      .post(`${apiUrl}deviceOrders/create`, params)
      .then((res) => {
        // didn't add a res.success check because there is processing in the component
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to create device order:', err);
        throw err;
      });
  }

  getDeviceOrderDetail(order_id, email) {
    return axios
      .get(`${apiUrl}deviceOrders/detail/${order_id}`, {
        params: { email },
      })
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get device order detail:', err);
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to device order detail:', err);
        throw err;
      });
  }

  updateDeviceOrderDetail(order_id, params) {
    return axios
      .post(`${apiUrl}deviceOrders/${order_id}/update`, params)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to update device order detail:', err);
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to update device order detail:', err);
        throw err;
      });
  }

  getAllBaseballGames(params) {
    Object.assign(params, { offset: new Date().getTimezoneOffset() });
    return axios
      .post(`${apiUrl}schedule/getAllBaseballGames`, params)
      .then((res) => {
        if (!res.data.data) {
          const games = [];
          return {
            data: games,
            success: true,
            page: res.data.page,
            totalCount: res.data.totalCount,
          };
        }
        const games = res.data.data;
        const gamesData = [];
        games.forEach((game) => {
          const game_icon = game.game_icon ? game.game_icon : 'no-icon';
          const start_time = game.game_start_ts;
          const game_data = {
            ...game,
            _id: game._id,
            away_pitches_saved: game.away_pitches_saved || false,
            home_pitches_saved: game.home_pitches_saved || false,
            game_id: game._id || '--',
            schedule_id: game.schedule_id || '--',
            game_name: game.name || '--',
            start_time: start_time || '--',
            game_start_ts: start_time || '--',
            group_name: game.group_name || '--',
            home_team: game.home_name || game.team_home_school || game.home_team_school || '',
            team_away_name: game.team_away_data ? game.team_away_data.name : '- -',
            team_home_name: game.team_home_data ? game.team_home_data.name : '- -',
            home_team_id: game.team_home_id || '--',
            away_team: game.away_name || game.team_away_school || games.away_team_school || '',
            away_team_id: game.team_away_id || '--',
            sport: game.sport ? game.sport : game.sport_data ? game.sport_data.name : '--',
            game_snapshot: game.game_snapshot || '--',
            game_icon,
            game_status: game.game_status,
            finalized: game.finalized && game.finalized === true,
            game_data: game.game_data || '',
            confirmed_by: game.confirmed_by || '--',
          };
          gamesData.push(game_data);
        });
        this.gamesData[params.date] = gamesData;
        return {
          data: gamesData,
          page: res.data.page,
          totalCount: res.data.totalCount,
          success: true,
        };
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get all baseball games:', err);
        return axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  getAllDeviceOrdersPaginated(params) {
    return axios
      .post(`${apiUrl}deviceOrders/`, params, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get all baseball games:', err);
        return axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  getAllPitchHistory(params) {
    const timzone_offset = new Date().getTimezoneOffset();
    Object.assign(params, { offset: timzone_offset });
    return axios
      .post(`${apiUrl}pitch_appearance/getPitchHistory`, params)
      .then((res) => {
        if (!res.data.data) {
          const pitches = [];
          return {
            data: pitches,
            success: true,
            page: res.data.page,
            totalCount: res.data.totalCount,
          };
        }
        const pitches = res.data.data;
        const gamesData = [];
        const getSubmitterAffiliation = (pitch) => {
          if (
            pitch.schedule_data
            && pitch.schedule_data.home_pitches_saved
            && pitch.schedule_data.away_pitches_saved
          ) {
            return (
              pitch.schedule_data.away_name
              || `${pitch.schedule_data.team_away_school} & ${pitch.schedule_data.home_name}`
              || pitch.schedule_data.team_home_school
              || 'Both'
            );
          }
          if (
            pitch.schedule_data
            && pitch.schedule_data.home_pitches_saved
            && !pitch.schedule_data.away_pitches_saved
          ) {
            return pitch.schedule_data.home_name || pitch.schedule_data.team_home_school || 'Home';
          }
          if (
            pitch.schedule_data
            && !pitch.schedule_data.home_pitches_saved
            && pitch.schedule_data.away_pitches_saved
          ) {
            return pitch.schedule_data.away_name || pitch.schedule_data.team_away_school || 'Away';
          }
          return '--';
        };
        pitches.forEach((pitch) => {
          const pitch_data = {
            ...pitch,
            _id: pitch._id,
            schedule_id: pitch.schedule_mongo_id,
            game_name: pitch.schedule_data ? pitch.schedule_data.name : '--',
            start_time: pitch.game_start_ts ? moment(pitch.game_start_ts).format('MM-DD-YY') : '--',
            game_start_ts: pitch.schedule_data ? pitch.schedule_data.game_start_ts : '--',
            team_name: pitch.team_data ? pitch.team_data.name : '--',
            entry_id: pitch._id || '--',
            entry_date: moment(pitch.date_created).format('MM-DD-YY') || '--',
            home_team_id: pitch.team_home_id || '--',
            pitcher_name: pitch.roster_data
              ? `${pitch.roster_data.first_name} ${pitch.roster_data.last_name}`
              : '--',
            away_team:
              pitch.schedule_data && pitch.schedule_data.away_name
                ? pitch.schedule_data.away_name
                : pitch.schedule_data && pitch.schedule_data.team_away_school
                  ? pitch.schedule_data.team_away_school
                  : '--',
            home_team:
              pitch.schedule_data && pitch.schedule_data.home_name
                ? pitch.schedule_data.home_name
                : pitch.schedule_data && pitch.schedule_data.team_home_school
                  ? pitch.schedule_data.team_home_school
                  : '--',
            away_team_id: pitch.schedule_data ? pitch.schedule_data.team_away_id : '--',
            sport: pitch.schedule_data ? pitch.schedule_data.sport : '--',
            pitches_thrown: pitch.total_pitches || '--',
            notes: pitch.notes || '--',
            submitter_affiliation: getSubmitterAffiliation(pitch),
            entered_by: pitch.entered_by || '--',
            entry_method: pitch.entry_from_web ? 'Web' : pitch.entry_from_mobile ? 'Mobile' : '--',
            roster_school:
              pitch.roster_data && pitch.roster_data.school_name
                ? pitch.roster_data.school_name
                : '--',
            roster_school_id:
              pitch.roster_data && pitch.roster_data.school_mongo_id
                ? pitch.roster_data.school_mongo_id
                : '',
          };
          gamesData.push(pitch_data);
        });
        this.gamesData[params.date] = gamesData;
        return {
          data: gamesData,
          page: res.data.page,
          totalCount: res.data.totalCount,
          success: true,
        };
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get all pitch history:', err);
        return axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
      });
  }

  getPitchHistoryDetails(params) {
    return axios
      .post(`${apiUrl}pitch_appearance/getPitchHistoryDetails`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get pitch history details:', err);
        throw err;
      });
  }

  addPitcher(params) {
    return axios
      .post(`${apiUrl}roster/addRoster`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to add pitcher:', err);
        throw err;
      });
  }

  saveCompletedPitchAppearances(params) {
    return axios
      .post(`${apiUrl}pitch_appearance/saveCompletedPitchAppearance`, params)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to save completed pitch appearances:', err);
          throw res.data.message;
        }
        return res;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to save completed pitch appearances:', err);
        throw err;
      });
  }

  saveScores(params) {
    return axios
      .post(`${apiUrl}game/saveScores`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to save scores:', err);
        throw err;
      });
  }

  createRequestToken(params) {
    return axios
      .post(`${apiUrl}twitter/createRequestToken`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to create request token:', err);
        throw err;
      });
  }

  twitterUserAuth(params) {
    return axios
        .post(`${apiUrlv4}twitter/user-auth`, params)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to create request token:', err);
          throw err;
        });
  }

  schoolQuickUpdate(params, schoolId) {
    return axios
      .put(`${apiUrl}school/schoolQuickUpdate/${schoolId}`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to school quick update:', err);
        throw err;
      });
  }

  // Params - obj with next fields:
  // Home: string <Home Score> (example, "2")
  // Away: string  <Away Score> (example, "0")
  // schedule_id: <schedules.schedule_id> (example, "60fb40c49e23ff4cdc51fa63")
  confirmAndFinalizeGame(params) {
    return axios
      // .post(`${apiUrl}game/confirmAndFinalizeGame`, params)
      .post(`${apiUrlv4}game/confirm-and-finalize`, params)
      .then((response) => {
        if (!response.data.success) {
          console.error(
            'Error when try to save completed pitch appearances:',
            response.data.message,
          );
        }
        return response.data;
      })
      .catch((err) => {
        console.error('Error when try to manuallyFedScores:', err);
        throw err;
      });
  }

  getFlaggedGames(user_id) {
    return axios
      .get(`${apiUrl}flaggedGame/${user_id}`)
      .then((resolve) => {
        return resolve.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get flagged games:', err);
        return err;
      });
  }

  setGameFlagged(payload) {
    return axios
      .post(`${apiUrl}flaggedGame`, payload)
      .then((response) => {
        if (!response.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to flag game:', response.data.message);
          throw response.data.message;
        }
        return response;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to flag game:', err);
        return err;
      });
  }

  deleteGameFlagged(payload) {
    return axios
      .delete(`${apiUrl}flaggedGame`, { data: payload })
      .then((response) => {
        if (!response.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to delete game from flagged list:', response.data.message);
          throw response.data.message;
        }
        return response;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to delete game from flagged list:', err);
        return err;
      });
  }

  getFacilityById(facility_id) {
    return axios
      .get(`${apiUrl}facility/${facility_id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error('Error when try to get facility by id:', err);
        throw err;
      });
  }

  getAllSchoolsNames() {
    return axios
      .get(`${apiUrl}school/getAllSchoolsNames`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error on fetching schools list from server:', res.data.message);
          throw res.data.message;
        }
        return res.data;
      })
      .catch((e) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.log('Error on fetching schools list from server, error: ', e);
        return e;
      });
  }

  getTeamsByIds(teamsArray) {
    return axios
      .post(`${apiUrlv4}team/getTeamsByIds`, teamsArray)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get teams by ids:', res.data.message);
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.log('Error when try to get teams by ids: ', error);
        return error;
      });
  }

  // Get teams for user by email (choosen teams for nootifications)
  getUserTeams(email) {
    return axios
      .get(`${apiUrlv4}team/byEmail/${email}`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get teams for user bt email:', res.data.message);
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.log('Error when try to get teams for user bt email: ', error);
        return error;
      });
  }

  // rewritten to new API
  // getGamesVers4(params, axiosParams) {
  //   cancel();
  //   return axios
  //     .post(
  //       `${apiUrlv4}game/getGamesBySchool`,
  //       { ...params, offset: new Date().getTimezoneOffset() },
  //       {
  //         cancelToken: new CancelToken((c) => {
  //           cancel = c;
  //         }),
  //         ...axiosParams,
  //       },
  //     )
  //     .then((res) => {
  //       if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
  //         const games = [];
  //         return {
  //           data: games,
  //           success: true,
  //           page: res.data.page,
  //           totalCount: res.data.totalCount,
  //         };
  //       }
  //       // const games = res.data.data;
  //       // this.gamesData[params.date] = games;
  //       return {
  //         data: res.data.data,
  //         page: res.data.page,
  //         totalCount: res.data.totalCount,
  //         success: true,
  //         ...res
  //       };
  //     })
  //     .catch((err) => {
  //       if (axiosInstance.isCancel(err)) {
  //         console.warn('game/getGamesByschool request is canceled by cancel token', axiosParams);
  //       } else {
  //         snackbarService.error(err.response?.data?.message || 'Something went wrong. Please, try later.');
  //         console.warn('Error on fetching games: ', err);
  //       }
  //       return axiosInstance.isCancel(err) ? { cancel: true } : { success: false };
  //     });
  // }

  getAssociationsList() {
    return axios.get(`${apiUrlv4}associations`).then((res)=>{
      if (res.status === 200) {
        if (!res.data || !res.data.success) {
          snackbarService.error('Error on getting associations list. Please try again later...');
          return;
        }
        return res.data.associations
      }
    }).catch(e => {
      snackbarService.error(e.message)
    })
  }

  removeUser(payload){
    return axios.delete(`${apiUrlv4}user/remove`, {data: {
        user_id: payload.user_id,
        user_id_to_remove: payload.user_id_to_remove,
        schools_to_remove: payload.schools_to_remove
      }}).then((res)=>{
      if (res.status === 200) {
        if (!res.data || !res.data.success) {
          snackbarService.error('Error on removing user. Please try again later...');
          return;
        }
        return res.data.success
      }
      return res
    }).catch(e => {
      snackbarService.error(e.message)
    })
  }

  getTournaments() {
    return axios.get(`${apiUrlv4}tournaments`).then((res)=>{
      if (res.status === 200) {
        if (!res.data || !res.data.success) {
          snackbarService.error('Error on getting tournaments. Please try again later...');
          return;
        }
        return res.data
      }
    }).catch(e => {
      snackbarService.error(e.message)
    })
  }
}

export default ScorebirdService;
