/* TODO: Remove current user object from store and replace with new one, with typings */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { IFilters } from 'src/components/common/header/components/headerFilters/HeaderFilters.interface';
import { Field } from 'src/types';

export enum ActionTypes {
  CURRENT_USER_SELECTED_SCHOOL_PITCH_COUNT = 'currentUser/selectedSchoolPitchCount',
  CURRENT_USER_SELECTED_FILTERS = 'currentUser/selectedFilters',
  CURRENT_USER_EDIT_SIGN_UP_USER = 'currentUser/editSignUpUser',
  CURRENT_USER_EDIT_RESEND_REQUEST = 'currentUser/editResendRequest',
}

interface CurrentUser {
  userEmail: string,
  currentUser: any | null;
  selectedCountry: Field;
  selectedState: Field;
  selectedAssociation: string | null;
  selectedSchool: Field;
  selectedSchoolPitchCount: Field,
  selectedFilters: IFilters,
  editSignUpUser: any | null;
  editResendRequest: any | null;
}

/* Initial state */
const initialState:CurrentUser = {
  userEmail: null,
  currentUser: null,
  selectedCountry: null,
  selectedState: null,
  selectedAssociation: null,
  selectedSchool: null,
  selectedSchoolPitchCount: null,
  selectedFilters: null,
  editSignUpUser: null,
  editResendRequest: null,
};

/* Actions */
const setUserEmail = createAction('currentUser/setUserEmail');
const setCurrentUser = createAction('currentUser/set');
const removeCurrentUser = createAction('currentUser/remove');
const selectedCountry = createAction('currentUser/selectedCountry');
const selectedState = createAction('currentUser/selectedState');
const selectedAssociation = createAction('currentUser/selectedAssociation');
const selectedSchool = createAction('currentUser/selectedSchool');
const selectedSchoolPitchCount = createAction(ActionTypes.CURRENT_USER_SELECTED_SCHOOL_PITCH_COUNT);
const selectedFilters = createAction(ActionTypes.CURRENT_USER_SELECTED_FILTERS);
const editSignUpUser = createAction(ActionTypes.CURRENT_USER_EDIT_SIGN_UP_USER);
const editResendRequest = createAction(ActionTypes.CURRENT_USER_EDIT_RESEND_REQUEST);

/* Reducer */
export const currentUserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserEmail, (state, action) => {
      state.userEmail = action.payload;
    })
    .addCase(setCurrentUser, (state, action) => {
      state.currentUser = action.payload;
    })
    .addCase(removeCurrentUser, (state) => {
      state.currentUser = initialState;
    })
    .addCase(selectedCountry, (state, action) => {
      state.selectedCountry = action.payload;
    })
    .addCase(selectedState, (state, action) => {
      state.selectedState = action.payload;
    })
    .addCase(selectedAssociation, (state, action) => {
      state.selectedAssociation = action.payload;
    })
    .addCase(selectedSchool, (state, action) => {
      state.selectedSchool = action.payload;
    })
    .addCase(selectedSchoolPitchCount, (state, action) => {
      state.selectedSchoolPitchCount = action.payload;
    })
    .addCase(selectedFilters, (state, action) => {
      state.selectedFilters = action.payload;
    })
    .addCase(editSignUpUser, (state, action) => {
      state.editSignUpUser = action.payload;
    })
    .addCase(editResendRequest, (state, action) => {
      state.editResendRequest = action.payload;
    });
});
