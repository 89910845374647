import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PartnerEnums } from 'src//enums/PartnerEnums';
import CloseIcon from 'src/assets/images/menu-icons/close.svg';
import Button from 'src/components/common/button/CustomButton';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';
import {
  TemporaryConfirmationModalNew,
} from 'src/components/common/modals/temporary-confirmation-modal-new/TemporaryConfirmationModalNew';
import { HandleLoadOptions } from 'src/components/common/select-async/SelectAsync.types';
import Spinner from 'src/components/common/spinner/Spinner';
import { AddTeam } from 'src/components/customer/teams/components/addTeam/AddTeam';
import statesByCountry, { State } from 'src/constants/statesByCountry';
import { ColorModalEnums } from 'src/enums/ColorModalEnums';
import { SportEnums } from 'src/enums/SportEnums';
import { StatusEnums } from 'src/enums/StatusEnums';
import { TeamEnums } from 'src/enums/TeamEnums';
import useAllSchoolsSelected from 'src/hooks/useAllSchoolsSelected';
import { useBroadcaster } from 'src/hooks/useBroadcaster';
import api from 'src/services/api';
import { AssociationsState } from 'src/store/types/associationsTypes';
import { RootState } from 'src/store/types/rootStateTypes';
import { FacilityFormField } from 'src/util/getFacility';
import { loadFacilities } from 'src/util/loadFacilities';

import Actions from './components/actions/Actions';
import AdditionalInformation from './components/additionalInformation/AdditionalInformation';
import GeneralInformation from './components/generalInformation/GeneralInformation';
import Team from './components/team/Team';
import { OTHER_OPTION, TBD_OPTION } from './constants/constants';
import useTeamOptions from './hooks/useHandleTeamOptions';
import usePreFill from './hooks/usePreFill';
import initialAddTeamModalState from './initialStates/initialAddTeamModalState';
import initialConfirmationModalState from './initialStates/initialConfirmationModalState';
import styles from './RegularScheduleForm.module.css';
import {
  AddTeamModal,
  ConfirmationModal,
  OnChange,
  RegularScheduleField,
  RegularScheduleFormData,
  RegularScheduleFormProps,
} from './RegularScheduleForm.types';
import { regularScheduleFormSchema } from './regularScheduleFormSchema';
import { fetchTeams } from './utils/fetchTeams';
import { formatScheduleToEdit } from './utils/formatScheduleToEdit';
import { formatScheduleToSave } from './utils/formatScheduleToSave';
import { generateTeamsPayload } from './utils/generateTeamsPayload';
import { getDefaultDate } from './utils/getDefaultDate';
import { getDisableStatus } from './utils/getDisableStatus';
import { getScheduleStatus } from './utils/getScheduleStatus';
import validateOnSubmit from './utils/validateOnSubmit';
import { whereIsGame } from './utils/whereIsGame';

const { HOME, AWAY } = TeamEnums;
const { BLUE, RED } = ColorModalEnums;
const { BASEBALL, SOFTBALL } = SportEnums;
const { RANKONE } = PartnerEnums;
const { ERROR } = StatusEnums;

const RegularScheduleForm = (props: RegularScheduleFormProps) => {
  const {
    isPitchCountSchedule = false,
    pitchCountScheduleDate,
    onScheduleAddedProps,
    onScheduleAdded,
    scheduleIdForEdit,
    onClose = null,
  } = props;

  const navigate = useNavigate();
  const { scheduleId: scheduleIdFromParams } = useParams();
  const scheduleId = scheduleIdForEdit || scheduleIdFromParams;

  const {
    currentUser, selectedCountry, selectedState, selectedAssociation, selectedSchool,
  } = useSelector((state: RootState) => state.currentUser);
  const { genders: genderOptions } = useSelector((state: RootState) => state.genders);
  const { grades: gradeOptions } = useSelector((state:RootState) => state.grades);
  const associationsList = useSelector((state: AssociationsState) => state.associations.associationsList);
  const userSchoolList = useSelector((state: RootState) => state.schools.list);

  const {
    useGetScheduleById, useGetTournaments, useAddSchedule, useUpdateSchedule,
  } = api.scheduleService();
  const { useGetSchools } = api.schoolsService();
  const { useGetTeams, useAddTeam } = api.teamsService();
  const { useGetFacilitiesMutation } = api.facilityService();
  const { data: scheduleData, isFetching: isScheduleLoading } = useGetScheduleById(scheduleId);

  const {
    data: homeTeamData, mutate: getHomeTeams, isLoading: isHomeTeamOptionsLoading, reset: resetHomeTeamData,
  } = useGetTeams();
  const {
    data: awayTeamData, mutate: getAwayTeams, isLoading: isAwayTeamOptionsLoading, reset: resetAwayTeamData,
  } = useGetTeams();

  const { mutateAsync: addTeam } = useAddTeam();

  const { data: tournamentsData } = useGetTournaments();

  const { mutateAsync: addSchedule, isLoading: isAddScheduleLoading } = useAddSchedule();
  const { mutateAsync: updateSchedule, isLoading: isUpdateScheduleLoading } = useUpdateSchedule();

  const { mutateAsync: getFacilities, isLoading: isFacilityOptionsLoading } = useGetFacilitiesMutation();

  const homeTeamOptions = useMemo(() => {
    return {
      ...homeTeamData,
      data: [...(homeTeamData?.data || []), OTHER_OPTION],
    };
  }, [homeTeamData]);

  const awayTeamOptions = useMemo(() => {
    return {
      ...awayTeamData,
      data: [...(awayTeamData?.data || []), OTHER_OPTION],
    };
  }, [awayTeamData]);

  const sportOptions: RegularScheduleField[] = useSelector((state:RootState) => {
    if (!isPitchCountSchedule) {
      return state.sports.sports;
    }
    return state.sports.sports.filter(
      ({ label }) => label.toLowerCase() === BASEBALL || label.toLowerCase() === SOFTBALL,
    );
  });

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(() => initialConfirmationModalState);
  const [addTeamModal, setAddTeamModal] = useState<AddTeamModal>(() => initialAddTeamModalState);

  const formattedSchedule = useMemo(() => formatScheduleToEdit(scheduleData?.data), [scheduleData?.data]);

  const { isBroadcaster } = useBroadcaster();
  const isSuperAdmin: boolean = currentUser?.super_admin;
  const isAssociationAdmin: boolean = currentUser?.association_admin;
  const isDisableStatus: boolean = getDisableStatus(formattedSchedule);
  const isRankOneSchedule: boolean = !!formattedSchedule?.mapping.find((s) => s.name === RANKONE);
  const isDisabledFacility: boolean = (formattedSchedule?.isDeviceOnline && formattedSchedule?.isLiveGame) || formattedSchedule?.finalized || isRankOneSchedule;

  const { isAllSchoolsSelected } = useAllSchoolsSelected();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<RegularScheduleFormData>({
    resolver: yupResolver(regularScheduleFormSchema(isSuperAdmin, isBroadcaster)),
    mode: 'onSubmit',
    defaultValues: {
      date: getDefaultDate(),
      country: null,
      sport: null,
      level: null,
      gender: null,
      facility: null,
      scheduleId: '',
      liveStreamURL: '',
      description: '',
      homeState: null,
      homeAssociation: null,
      homeSchool: null,
      homeTeam: null,
      homeTeamOther: '',
      awayState: null,
      awayAssociation: null,
      awaySchool: null,
      awayTeam: null,
      awayTeamOther: '',
      cancelled: false,
      postponed: false,
      inactive: false,
      tournament: null,
    },
  });

  const currentUserCountry = currentUser?.country;
  const watchCountryValue = !scheduleId ? (watch('country')?.value || currentUser.school_data[0]?.country) : formattedSchedule?.country;

  const watchLevelValue = watch('level')?.value;
  const watchLevelLabel = watch('level')?.label;
  const watchGenderValue = watch('gender')?.value;
  const watchGenderLabel = watch('gender')?.label;
  const watchSportValue = watch('sport')?.value;
  const watchSportLabel = watch('sport')?.label;

  const watchHomeStateValue = watch('homeState')?.value;
  const watchHomeAssociationName = watch('homeAssociation')?.name;
  const watchHomeSchoolValue = watch('homeSchool')?.value;
  const watchHomeSchoolLabel = watch('homeSchool')?.label;
  const watchHomeTeamValue = watch('homeTeam')?.value;

  const watchAwayStateValue = watch('awayState')?.value;
  const watchAwayAssociationName = watch('awayAssociation')?.name;
  const watchAwaySchoolValue = watch('awaySchool')?.value;
  const watchAwaySchoolLabel = watch('awaySchool')?.label;
  const watchAwayTeamValue = watch('awayTeam')?.value;

  const transformedAssociationsList = useMemo(() => {
    return associationsList?.map(
      (association) => ({
        value: association.data._id,
        label: <div>{association.data.name.toUpperCase()} {association.data?.logo && <img className={styles.associationLogo} src={association.data.logo} alt={`${association.data.name}-logo`} /> }</div>,
        state: association.data.state,
        name: association.data.name,
      }),
    );
  }, [associationsList]);

  // Normal / Association / Broadcaster:
  const preFilledSchool = currentUser.school_data?.find((s: any) => s._id === selectedSchool?.value) || currentUser.school_data[0];
  const preFilledState = statesByCountry[selectedCountry?.value || currentUser.school_data[0]?.country]?.find((s: State) => s.value === preFilledSchool.state);
  const preFilledAssociation = transformedAssociationsList?.find((association) => association.value === (selectedSchool?.associationId || userSchoolList[0]?.associationId));
  const areMultipleStates = !isSuperAdmin && Array.isArray(selectedSchool?.state) && selectedSchool?.state.length > 1;

  usePreFill({
    scheduleId,
    isSuperAdmin,
    isAssociationAdmin,
    setValue,
    selectedCountry,
    selectedState,
    selectedAssociation,
    selectedSchool,
    transformedAssociationsList,
    isAllSchoolsSelected,
    currentUserCountry,
    preFilledState,
    preFilledSchool,
    preFilledAssociation,
    areMultipleStates,
  });

  const showCreateAutomaticallyHomeTeam = watchLevelValue
    && watchGenderValue
    && watchSportValue
    && watchHomeStateValue
    && ((watchHomeSchoolValue !== TBD_OPTION.value) && watchHomeSchoolValue)
    && !isHomeTeamOptionsLoading
    && homeTeamOptions.data.length === 1;

  const showCreateAutomaticallyAwayTeam = watchLevelValue
    && watchGenderValue
    && watchSportValue
    && watchAwayStateValue
    && ((watchAwaySchoolValue !== TBD_OPTION.value) && watchAwaySchoolValue)
    && !isAwayTeamOptionsLoading
    && awayTeamOptions.data.length === 1;

  const isHomeTeamError = !!errors.homeTeam?.message;
  const isAwayTeamError = !!errors.awayTeam?.message;

  const homeSchoolsPayload = {
    country: watchCountryValue,
    state: watchHomeStateValue,
    association: watchHomeAssociationName,
  };

  const awaySchoolsPayload = {
    country: watchCountryValue,
    state: watchAwayStateValue,
    association: watchAwayAssociationName,
  };

  const {
    data: homeSchools,
    isFetching: isHomeSchoolsLoading,
  } = useGetSchools(homeSchoolsPayload, HOME);

  const {
    data: awaySchools,
    isFetching: isAwaySchoolsLoading,
  } = useGetSchools(awaySchoolsPayload, AWAY);

  const homeSchoolOptions = useMemo(() => {
    return {
      ...homeSchools,
      data: [TBD_OPTION, ...(homeSchools?.data || [])],
    };
  }, [homeSchools]);

  const awaySchoolOptions = useMemo(() => {
    return {
      ...awaySchools,
      data: [TBD_OPTION, ...(awaySchools?.data || [])],
    };
  }, [awaySchools]);

  const filterHomeAssociations = useMemo(() => {
    return transformedAssociationsList?.filter(
      (association) => {
        return association.state === watchHomeStateValue;
      },
    );
  }, [watchHomeStateValue]);

  const filterAwayAssociations = useMemo(() => {
    return transformedAssociationsList?.filter(
      (association) => {
        return association.state === watchAwayStateValue;
      },
    );
  }, [watchAwayStateValue]);

  useEffect(() => {
    if (!(scheduleId && formattedSchedule?._id)) {
      return;
    }

    // General Information:
    setValue('date', formattedSchedule.date);

    if (isSuperAdmin) {
      setValue('country', formattedSchedule?.country ? { value: formattedSchedule.country, label: formattedSchedule.country } : { value: 'US', label: 'US' });
    }

    setValue('sport', sportOptions?.find((sport: RegularScheduleField) => sport.value === formattedSchedule?.sportId));
    setValue('level', gradeOptions?.find((grade: RegularScheduleField) => grade.value === formattedSchedule?.gradeId));
    setValue('gender', genderOptions?.find((gender: RegularScheduleField) => gender.value === formattedSchedule?.genderId));

    // Additional Information:
    setValue('facility', formattedSchedule.facility);
    setValue('liveStreamURL', formattedSchedule?.liveStreamURL || '');
    setValue('description', formattedSchedule?.description || '');
    setValue('tournament', tournamentsData?.data.find((tournament) => tournament.value === formattedSchedule.tournamentId));

    // Home Team:
    setValue('homeState', statesByCountry[formattedSchedule.country || 'US'].find((state: State) => state.value === formattedSchedule.homeState));
    setValue('homeAssociation', transformedAssociationsList?.find((association) => association.value === formattedSchedule?.homeAssociationId));
    setValue('homeTeamOther', !formattedSchedule.homeTeamId ? formattedSchedule?.homeTeamOther : '');

    // Away Team:
    setValue('awayState', statesByCountry[formattedSchedule.country || 'US'].find((state: State) => state.value === formattedSchedule.awayState));
    setValue('awayAssociation', transformedAssociationsList?.find((association) => association.value === formattedSchedule?.awayAssociationId));
    setValue('awayTeamOther', !formattedSchedule.awayTeamId ? formattedSchedule?.awayTeamOther : '');

    setValue('cancelled', formattedSchedule.cancelled || false);
    setValue('postponed', formattedSchedule.postponed || false);
    setValue('inactive', formattedSchedule.inactive || false);

    setValue('scheduleId', formattedSchedule._id);
  }, [formattedSchedule, tournamentsData]);

  // Set Schools for Home
  useEffect(() => {
    if (scheduleId && homeSchoolOptions?.data.length > 1) {
      setValue('homeSchool', homeSchoolOptions?.data.find((school) => school.value === formattedSchedule.homeSchoolId) || TBD_OPTION);
    }
  }, [isHomeSchoolsLoading, formattedSchedule, homeSchoolOptions?.data.length]);

  // Set Schools for Away
  useEffect(() => {
    if (scheduleId && awaySchoolOptions?.data.length > 1) {
      setValue('awaySchool', awaySchoolOptions?.data.find((school) => school.value === formattedSchedule.awaySchoolId) || TBD_OPTION);
    }
  }, [isAwaySchoolsLoading, formattedSchedule, awaySchoolOptions?.data.length]);

  // Set Teams for Home
  useEffect(() => {
    if (scheduleId && formattedSchedule && homeTeamOptions?.data.length === 1) {
      setValue('homeTeam', OTHER_OPTION);
    }

    if (scheduleId && homeTeamOptions?.data.length > 1) {
      setValue('homeTeam', homeTeamOptions?.data.find((team) => team.value === formattedSchedule.homeTeamId) || OTHER_OPTION);
    }
  }, [formattedSchedule, homeTeamOptions?.data.length]);

  // Set Teams for Away
  useEffect(() => {
    if (scheduleId && formattedSchedule && awayTeamOptions?.data.length === 1) {
      setValue('awayTeam', OTHER_OPTION);
    }

    if (scheduleId && awayTeamOptions?.data.length > 1) {
      setValue('awayTeam', awayTeamOptions?.data.find((team) => team.value === formattedSchedule.awayTeamId) || OTHER_OPTION);
    }
  }, [formattedSchedule, awayTeamOptions?.data.length]);

  useEffect(() => {
    fetchTeams({
      schoolValue: watchHomeSchoolValue,
      watchGenderValue,
      watchLevelValue,
      watchSportValue,
      getTeams: getHomeTeams,
      resetTeamData: resetHomeTeamData,
    });
  }, [watchHomeSchoolValue, watchGenderValue, watchLevelValue, watchSportValue]);

  useEffect(() => {
    fetchTeams({
      schoolValue: watchAwaySchoolValue,
      watchGenderValue,
      watchLevelValue,
      watchSportValue,
      getTeams: getAwayTeams,
      resetTeamData: resetAwayTeamData,
    });
  }, [watchAwaySchoolValue, watchGenderValue, watchLevelValue, watchSportValue]);

  // Autofill home team selector with first item in options list
  useTeamOptions({
    isDirty,
    teamOptions: homeTeamOptions,
    fieldName: 'homeTeam',
    setValue,
  });

  // Autofill away team selector with first item in options list
  useTeamOptions({
    isDirty,
    teamOptions: awayTeamOptions,
    fieldName: 'awayTeam',
    setValue,
  });

  const handleCountryChange = (value: RegularScheduleField, onChange: OnChange) => {
    onChange(value); // use onChange to avoid bug with shouldValidate
    setValue('homeState', null);
    setValue('homeAssociation', null);
    setValue('homeSchool', null);
    setValue('homeTeam', null);

    setValue('awayState', null);
    setValue('awayAssociation', null);
    setValue('awaySchool', null);
    setValue('awayTeam', null);

    setValue('facility', null);
  };

  const handleStateChange = (value: RegularScheduleField, type: TeamEnums, onChange: OnChange) => {
    onChange(value); // use onChange to avoid bug with shouldValidate
    setValue(`${type}Association`, null);
    setValue(`${type}School`, null);
    setValue(`${type}Team`, null);
  };

  const handleAssociationChange = (value: RegularScheduleField, type: TeamEnums, onChange: OnChange) => {
    onChange(value);
    setValue(`${type}School`, null);
    setValue(`${type}Team`, null);
  };

  const handleSchoolChange = (value: RegularScheduleField, type: TeamEnums, onChange: OnChange) => {
    onChange(value); // use onChange to avoid bug with shouldValidate
    setValue(`${type}Team`, null);
  };

  const handleFacilityChange = (value: FacilityFormField, onChange: OnChange) => {
    if (!value || value?.deviceSerial) {
      onChange(value);
      return;
    }

    setConfirmationModal({
      ...confirmationModal,
      open: true,
      type: RED,
      title: 'Attention!',
      message: 'There is no Scorebird device assigned to the facility you have chosen \nfor this schedule.',
      approveTitle: 'Ignore & Continue',
      cancelTitle: 'Go Back',
      approveFn: () => {
        onChange(value);
        setConfirmationModal(initialConfirmationModalState);
      },
      cancelFn: () => {
        setConfirmationModal(initialConfirmationModalState);
      },
    });
  };

  const resetSchedule = () => {
    reset();
    setConfirmationModal(initialConfirmationModalState);
  };

  const handleReset = () => {
    setConfirmationModal({
      ...confirmationModal,
      open: true,
      type: RED,
      title: 'Are you sure you want to Reset? All your\n edits will be undone.',
      approveTitle: 'Yes, reset it',
      approveFn: () => resetSchedule(),
      cancelFn: () => setConfirmationModal(initialConfirmationModalState),
    });
  };

  const handleCreateAutomaticallyTeam = async (type: TeamEnums) => {
    const teamName = `${watchGenderLabel} ${watchLevelLabel} ${watchSportLabel}`;

    const payload = {
      country: watchCountryValue,
      state: type === HOME ? watchHomeStateValue : watchAwayStateValue,
      school_id: type === HOME ? watchHomeSchoolValue : watchAwaySchoolValue,
      school_mongo_id: type === HOME ? watchHomeSchoolValue : watchAwaySchoolValue,
      name: teamName,
      sport: watchSportValue,
      gender: watchGenderLabel.toLowerCase(),
      level: watchLevelLabel.toLowerCase(),
    };

    const res = await addTeam(payload);

    if (res.success) {
      const teamsPayload = generateTeamsPayload({
        type,
        watchHomeSchoolValue,
        watchAwaySchoolValue,
        watchGenderValue,
        watchLevelValue,
        watchSportValue,
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      type === HOME ? getHomeTeams(teamsPayload) : getAwayTeams(teamsPayload);

      setConfirmationModal(initialConfirmationModalState);
    }
  };

  const handleCustomizeTeam = (type: TeamEnums) => {
    setConfirmationModal(initialConfirmationModalState);
    setAddTeamModal({
      type,
      open: true,
      country: watchCountryValue,
      stateId: type === HOME ? watchHomeStateValue : watchAwayStateValue,
      schoolId: type === HOME ? watchHomeSchoolValue : watchAwaySchoolValue,
      sportId: watchSportValue,
      genderLabel: watchGenderLabel?.toLowerCase(),
      levelId: watchLevelValue,
    });
  };

  const handleAddTeamClick = (type: TeamEnums) => {
    const teamName = `${watchGenderLabel} ${watchLevelLabel} ${watchSportLabel}`;
    const schoolName = type === HOME ? `${watchHomeSchoolLabel}` : `${watchAwaySchoolLabel}`;

    setConfirmationModal({
      ...initialConfirmationModalState,
      open: true,
      type: BLUE,
      title: `Would you like to proceed and create the ${teamName} team in ${schoolName}?`,
      approveFn: () => handleCreateAutomaticallyTeam(type),
      closeFn: () => setConfirmationModal(initialConfirmationModalState),
      cancelFn: () => handleCustomizeTeam(type),
      approveTitle: 'Ok',
      cancelTitle: 'Customize',
    });
  };

  const handleAddTeam = (type: TeamEnums) => {
    const teamsPayload = generateTeamsPayload({
      type,
      watchHomeSchoolValue,
      watchAwaySchoolValue,
      watchGenderValue,
      watchLevelValue,
      watchSportValue,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    type === HOME ? getHomeTeams(teamsPayload) : getAwayTeams(teamsPayload);

    setAddTeamModal(initialAddTeamModalState);
  };

  const handleCloseAddTeamModal = () => {
    setAddTeamModal(initialAddTeamModalState);
  };

  const handleLoadFacilities: HandleLoadOptions = async (...args) => {
    return loadFacilities({
      ...args,
      currentUserSelectedSchools: currentUser.selected_schools,
      getFacilities,
    });
  };

  const handleCloseSchedule = () => {
    const onCloseHandle = () => {
      return onClose ? onClose() : navigate('/schedules');
    };

    if (isDirty) {
      setConfirmationModal({
        ...confirmationModal,
        open: true,
        type: RED,
        title: 'Are you sure you want to exit? Your\n changes will not be saved.',
        approveFn: () => onCloseHandle(),
        cancelFn: () => setConfirmationModal(initialConfirmationModalState),
      });
      return;
    }

    onCloseHandle();
  };

  const processSubmit = async (form: RegularScheduleFormData) => {
    const {
      facility,
      homeSchool,
      awaySchool,
      homeTeam,
      awayTeam,
    } = form;

    const validateStatus = await validateOnSubmit({
      isSuperAdmin,
      isBroadcaster,
      isAssociationAdmin,
      currentUserSelectedSchools: currentUser.selected_schools,
      facilityAssignedSchool: facility?.assignedSchool,
      confirmationModal,
      setConfirmationModal,
      homeSchool,
      awaySchool,
      homeTeam,
      awayTeam,
      partners: formattedSchedule?.mapping,
    });

    if (validateStatus === ERROR) return;

    const formData = {
      ...form,
      _id: scheduleId,
      email: currentUser?.email,
      where: whereIsGame(form.awayTeam, form.awaySchool, currentUser.selected_schools),
      country: form.homeState?.countryCode || form.awayState?.countryCode,
    };

    const payload = formatScheduleToSave(formData);

    const res = !scheduleId ? await addSchedule(payload) : await updateSchedule(payload);

    if (res.success) {
      if (onScheduleAdded) {
        onScheduleAdded(onScheduleAddedProps
          ? {
            ...onScheduleAddedProps,
            response: res,
            fieldIndex: onScheduleAddedProps.fieldIndex,
            facilityAssignedSchool: facility?.assignedSchool,
          }
          : {
            response: res,
            facilityAssignedSchool: facility?.assignedSchool,
          });
      } else {
        navigate('/schedules', { state: { refetchSchedules: true } });
      }
    }
  };

  return (
    <ModalWindow isOpen className={styles.scheduleFormModal}>
      <Button
        version="icon"
        onClick={handleCloseSchedule}
        className={styles.scheduleFormCloseBtn}
      >
        <img src={CloseIcon} alt="close" />
      </Button>
      <h4 className={styles.scheduleFormTitle}>{scheduleId ? 'Edit' : 'Add'} Schedule {getScheduleStatus(formattedSchedule)}</h4>

      {isScheduleLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.scheduleFormWrapper}>
            <GeneralInformation
              control={control}
              sportOptions={sportOptions}
              gradeOptions={gradeOptions}
              genderOptions={genderOptions}
              pitchCountScheduleDate={pitchCountScheduleDate}
              watchLevelValue={watchLevelValue}
              isSuperAdmin={isSuperAdmin}
              isEdit={!!scheduleId}
              isDisabled={isDisableStatus}
              handleCountryChange={handleCountryChange}
            />

            <AdditionalInformation
              control={control}
              isSuperAdmin={isSuperAdmin}
              isAssociationAdmin={isAssociationAdmin}
              isDisabledFacility={isDisabledFacility}
              isDisabled={isDisableStatus}
              tournaments={tournamentsData?.data}
              isFacilityOptionsLoading={isFacilityOptionsLoading}
              handleFacilityChange={handleFacilityChange}
              handleLoadFacilities={handleLoadFacilities}
            />

            <Team
              type={HOME}
              control={control}
              isSuperAdmin={isSuperAdmin}
              isAssociationAdmin={isAssociationAdmin}
              currentUserCountry={currentUserCountry}
              watchCountryValue={watchCountryValue}
              associationOptions={filterHomeAssociations}
              schoolOptions={homeSchoolOptions?.data}
              teamOptions={homeTeamOptions?.data}
              isSchoolOptionsLoading={isHomeSchoolsLoading}
              isTeamOptionsLoading={isHomeTeamOptionsLoading}
              isTeamOther={watchHomeTeamValue === OTHER_OPTION.value}
              showCreateAutomatically={showCreateAutomaticallyHomeTeam}
              isTeamError={isHomeTeamError}
              isDisabled={isDisableStatus}
              isSchoolDisabled={!watchHomeStateValue}
              isTeamDisabled={!watchHomeSchoolValue}
              handleStateChange={handleStateChange}
              handleAssociationChange={handleAssociationChange}
              handleSchoolChange={handleSchoolChange}
              handleAddTeamClick={handleAddTeamClick}
            />

            <Team
              type={AWAY}
              control={control}
              isSuperAdmin={isSuperAdmin}
              isAssociationAdmin={isAssociationAdmin}
              currentUserCountry={currentUserCountry}
              watchCountryValue={watchCountryValue}
              associationOptions={filterAwayAssociations}
              schoolOptions={awaySchoolOptions?.data}
              teamOptions={awayTeamOptions?.data}
              isSchoolOptionsLoading={isAwaySchoolsLoading}
              isTeamOptionsLoading={isAwayTeamOptionsLoading}
              isTeamOther={watchAwayTeamValue === OTHER_OPTION.value}
              showCreateAutomatically={showCreateAutomaticallyAwayTeam}
              isTeamError={isAwayTeamError}
              isDisabled={isDisableStatus}
              isSchoolDisabled={!watchAwayStateValue}
              isTeamDisabled={!watchAwaySchoolValue}
              handleStateChange={handleStateChange}
              handleAssociationChange={handleAssociationChange}
              handleSchoolChange={handleSchoolChange}
              handleAddTeamClick={handleAddTeamClick}
            />
          </div>

          <Actions
            control={control}
            scheduleId={formattedSchedule?._id}
            created={formattedSchedule?.created}
            updated={formattedSchedule?.updated}
            partners={formattedSchedule?.mapping}
            rankoneFacilityId={formattedSchedule?.facility?.rankoneId}
            isSuperAdmin={isSuperAdmin}
            isEdit={!!scheduleId}
            isDisabled={isDisableStatus}
            isLoading={isAddScheduleLoading || isUpdateScheduleLoading}
            handleReset={handleReset}
            handleCancel={handleCloseSchedule}
            handleSubmit={handleSubmit(processSubmit)}
          />
        </>
      )}

      {confirmationModal.open && (
        <TemporaryConfirmationModalNew
          type={confirmationModal.type}
          title={confirmationModal.title}
          message={confirmationModal.message}
          approveFn={confirmationModal.approveFn}
          closeFn={confirmationModal.closeFn}
          cancelFn={confirmationModal.cancelFn}
          approveTitle={confirmationModal.approveTitle}
          cancelTitle={confirmationModal.cancelTitle}
          hideCancelBtn={confirmationModal.hideCancelBtn}
        />
      )}

      {addTeamModal.open && (
        <AddTeam
          addTeamData={{
            type: addTeamModal.type,
            country: addTeamModal.country,
            stateId: addTeamModal.stateId,
            schoolId: addTeamModal.schoolId,
            sportId: addTeamModal.sportId,
            genderLabel: addTeamModal.genderLabel,
            levelId: addTeamModal.levelId,
          }}
          addTeamFn={handleAddTeam}
          closeFn={handleCloseAddTeamModal}
        />
      )}
    </ModalWindow>
  );
};

export default RegularScheduleForm;
