import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import api from 'src/services/api';

export const useFetchGrades = () => {
  const dispatch = useDispatch();
  const { getAllTeamGrades } = api.gradeService();
  const [grades, setGrades] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const getGrades = () => {
    setIsFetching(true);
    getAllTeamGrades()
      .then((res) => {
        if (res.data.success) {
          setGrades(res.data.data);
        } else {
          console.error('Error fetching grades');
        }
      }).finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    dispatch({
      type: 'grades/setGrades',
      payload: grades,
    });
  }, [grades]);

  useEffect(() => {
    getGrades();
  }, []);

  return { grades, isFetching, getGrades };
};
