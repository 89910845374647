import { createAction, createReducer } from '@reduxjs/toolkit';

interface IToken {
  token: string | null;
}

/* Initial state */
const initialState: IToken = {
  token: null,
};

/* Actions */
const setToken = createAction('token/set');

/* Reducer */
export const tokenReducer = createReducer(initialState, (builder) => {
  builder.addCase(setToken, (state, action) => {
    state.token = action.payload;
  });
});
