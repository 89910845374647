import { RegularScheduleField } from 'src/components/customer/schedules/components/regular-schedules/regular-schedule-form/RegularScheduleForm.types';
import { FacilitiesRes, GetFacilities } from 'src/services/interfaces/Facility.types';

type LoadFacilitiesProps = {
  '0': string,
  '1': RegularScheduleField[],
  '2': { page: number },
  currentUserSelectedSchools: string[],
  getFacilities: (payload: GetFacilities) => Promise<FacilitiesRes>,
};

export const loadFacilities = async (props: LoadFacilitiesProps) => {
  const {
    0: search,
    1: prevOptions,
    2: { page },
    currentUserSelectedSchools,
    getFacilities,
  } = props;

  const optionsPerPage = 12;

  const payload = {
    user_schools: currentUserSelectedSchools,
    pageSize: optionsPerPage,
    page,
    filters: {
      search,
    },
  };

  const result: FacilitiesRes = await getFacilities(payload);

  const { user_facilities, all_facilities, all_facilities_count } = result;

  const mergeFacilities = [...user_facilities, ...all_facilities];

  const formatFacilities = mergeFacilities?.map((school) => {
    const updatedData = school.data?.map((facility) => {
      const {
        id, display_name, name, device_serial,
      } = facility;
      return {
        value: id,
        label: display_name || name,
        deviceSerial: device_serial,
        assignedSchool: school?.id,
      };
    });

    return {
      value: school?.id,
      label: `${school?.display_name || school?.name} (${school.state})`,
      options: updatedData,
    };
  });

  const start = page === 1 ? 0 : user_facilities.length;
  const end = formatFacilities.length;
  const hasMore = !search ? prevOptions.length <= all_facilities_count : !!all_facilities?.length;

  const slicedOptions = formatFacilities.slice(
    start,
    end,
  );

  return {
    options: slicedOptions,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};
