import React, { useCallback, useState } from 'react';

import cn from 'classnames';
import DatePicker from 'react-datepicker';
import Calendar from 'src/assets/images/calendar/calendar-icon.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

import Header from './components/header/Header';
import styles from './DatePickerInput.module.css';
import { DatePickerInputProps } from './DatePickerInput.types';

export const DatePickerInput = (props: DatePickerInputProps) => {
  const {
    className,
    popperClassName,
    timePicker = false,
    isSelectRange = false,
    startDate: startDateProp = null,
    endDate: endDateProp = null,
    dateFormat = 'M/dd/yyyy',
    value,
    label = '',
    onChange,
    onChangeProps,
    isDisabled = false,
    readOnly = false,
    showIcon = true,
    pitchCountScheduleDate = null,
    addEntryStyle = false,
    errorMsg,
  } = props;

  const [dateRange, setDateRange] = useState([value || startDateProp, endDateProp]);
  const [startDate, endDate] = dateRange;

  const onDateChangeCallback = useCallback((inputDate) => {
    onChange(onChangeProps
      ? { date: inputDate, fieldIndex: onChangeProps?.fieldIndex, onChange: onChangeProps?.onChange }
      : inputDate);
  }, [startDate]);

  const onDateChange = (update: Date | Date[]) => {
    // Using Range Picker in Schedules and Pitch Count
    if (Array.isArray(update)) {
      setDateRange(update);
      onDateChangeCallback(update);
      return;
    }

    setDateRange([update]);
    onDateChangeCallback(update);
  };

  const onCalendarClose = () => {
    if (isSelectRange && !dateRange[1]) {
      setDateRange([dateRange[0], dateRange[0]]);
      onDateChangeCallback([dateRange[0], dateRange[0]]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{label}</span>
      <DatePicker
        popperClassName={popperClassName}
        renderCustomHeader={Header}
        showTimeSelect={timePicker}
        className={cn(
          className,
          styles.input,
          addEntryStyle && styles.addEntryInput,
          isDisabled && styles.notAllowedArea,
          readOnly && styles.readOnly,
          errorMsg && styles.errorMsg,
        )}
        selected={new Date(value || startDate)}
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
        onCalendarClose={onCalendarClose}
        minDate={pitchCountScheduleDate}
        maxDate={pitchCountScheduleDate}
        timeIntervals={5}
        dateFormat={dateFormat}
        disabled={isDisabled}
        readOnly={readOnly}
        popperPlacement="bottom-start"
        fixedHeight
        selectsRange={isSelectRange}
        toggleCalendarOnIconClick
        showIcon={showIcon}
        placeholderText="Select Date"
        icon={(
          <img
            className={cn(
              styles.icon,
              addEntryStyle && styles.addEntryStyleIcon,
              readOnly && styles.notAllowedArea,
            )}
            src={Calendar}
            alt="calendar"
          />
        )}
      />
    </div>
  );
};
