import { createAction, createReducer } from '@reduxjs/toolkit';
import { getCorrectLevelName } from 'src/util/getCorrectLevelName';

interface Grades {
  grades: any[],
  detailedList: any[],
}

/* Initial state */
const initialState: Grades = {
  grades: [],
  detailedList: [],
};

/* Actions */
const setGrades = createAction('grades/setGrades', (grades: any[]) => ({
  payload: grades,
}));

/* Reducer */
export const gradesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setGrades, (state, action) => {
    state.grades = action.payload?.map((g) => ({ label: getCorrectLevelName(g.team_grade), value: g._id }))
      .sort((a, b) => a.label.localeCompare(b.label));
    state.detailedList = action.payload;
  });
});
