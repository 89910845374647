import React from 'react';

import cn from 'classnames';
import { AsyncPaginate, reduceGroupedOptions } from 'react-select-async-paginate';
import { LoadingIndicator } from 'src/components/common/select/components/LoadingIndicator';
import { style } from 'src/components/common/select/Select';

import Option from '../select/components/Option';
import styles from '../select/Select.module.css';
import ClearIndicator from './clearIndicator/ClearIndicator';
import GroupHeading from './groupHeading/GroupHeading';
import { AdditionalType, SelectAsyncProps } from './SelectAsync.types';
import { filterOption } from './utils/filterOption';

export const SelectAsyncPaginate = (props: SelectAsyncProps) => {
  const {
    label,
    placeholder,
    loadOptions,
    value,
    onChange,
    selectRef,
    onBlur,
    selectStyles,
    menuPlacement = 'top',
    isClearable = true,
    isDisabled = false,
    toolTipClearButton = '',
    isLoading,
    errorMsg,
  } = props;

  const defaultAdditional: AdditionalType = {
    page: 1,
  };

  return (
    <div className={cn(styles.selectWrapper)}>
      {label && <div className="form__input_label">{label}</div>}
      <AsyncPaginate
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        selectRef={selectRef}
        onBlur={onBlur}
        debounceTimeout={500}
        additional={defaultAdditional}
        loadOptions={loadOptions}
        isLoading={isLoading}
        styles={selectStyles || style.select}
        filterOption={filterOption}
        isClearable={isClearable}
        isDisabled={isDisabled}
        blurInputOnSelect
        defaultOptions
        reduceOptions={reduceGroupedOptions}
        menuPlacement={menuPlacement}
        // menuShouldScrollIntoView={false}
        // @ts-ignore
        errorMsg={errorMsg}
        components={{
          Option,
          GroupHeading,
          LoadingIndicator,
          ClearIndicator: (values) => ClearIndicator({ ...values, toolTipClearButton }),
        }}
      />
      {errorMsg && (
        <span
          className={cn(styles.errorMessage)}
        >
          {errorMsg}
        </span>
      )}
    </div>
  );
};
