import { capitalizeFirstLetter } from 'src/components/customer/teams/teamUtils';
import { getGender } from 'src/util/getGender';

export const generateName = ({
  gender, level, sport, sublevel,
}) => {
  // [Gender] [Level] ([SubLevel if present]) [Sport]
  const name = [
    gender && getGender(level?.label, gender?.value),
    level && (level.label.toLowerCase() === 'college' ? '' : capitalizeFirstLetter(level.label)),
    sublevel && `(${capitalizeFirstLetter(sublevel)})`,
    sport && capitalizeFirstLetter(sport.label),
  ];
  return name.filter((n) => n).join(' ');
};
