import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

export const SwitchInput = ({
  checked,
  onChange,
  inputRef = null,
  onBlur = null,
  disabled = false,
}) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        ref={inputRef}
        onBlur={onBlur}
        disabled={disabled}
      />
      <span className={cn('slider', disabled && 'disabled')} />
    </label>
  );
};

SwitchInput.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

SwitchInput.defaultProps = {
  checked: false,
  onChange: () => {},
};
