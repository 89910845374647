import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4, apiUrlV3 } from 'src/services/constants/api-versions';
import {
  AddTeam,
  GetTeams,
  SearchTeamsBySchoolPayload,
  TeamRes,
  TeamsBySchoolPayload,
  TeamsBySchoolRes,
  TeamsPaginatedPayload,
} from 'src/services/interfaces/Teams.types';

export const TeamsService = (axios: AxiosInstance) => {

  const GetTeamsPaginated = () => {
    const getTeamsPaginated = (payload: TeamsPaginatedPayload) => axios
      .post(`${apiUrlV4}team/getAll/`, payload)
      .then((res) => {
        return res.data;
      });

    return useMutation(
      'query-teams-paginated',
      getTeamsPaginated,
      {
        onError: (error) => {
          snackbarService.error("Can't get all paginated teams. Please, try later.");
          console.error('Error in get all paginated teams:', error);
        },
      },
    );
  };

  const useGetTeamsBySchools = (payload: TeamsBySchoolPayload) => {
    const { school_id } = payload;

    const getTeamsBySchools = () => axios
      .post(`${apiUrlV4}team/by-school`, payload)
      .then((res) => {
        const dataRes: TeamsBySchoolRes = res?.data;
        return dataRes;
      });

    return useQuery({
      queryKey: ['get-teams-by-school', payload],
      queryFn: getTeamsBySchools,
      refetchOnWindowFocus: false,
      enabled: !!school_id?.length, // Fetch when has the params
      staleTime: Infinity,
      cacheTime: 3 * 60 * 1000,
    });
  };

  // The same as getTeamsPaginated:
  const useGetTeams = () => {
    const getTeams = (payload: GetTeams) => axios
      .post(`${apiUrlV4}team/getAll`, payload)
      .then((res) => {
        const formattedTeams: TeamRes = {
          ...res.data,
          data: res.data.data.map((team) => ({
            ...team,
            value: team._id,
            label: team.name,
          })),
        };

        return formattedTeams;
      });

    return useMutation(
      getTeams,
      {
        mutationKey: ['mutation-get-teams'],
        onError: (error) => {
          snackbarService.error("Can't get teams. Please, try later.");
          console.error("Can't get teams:", error);
        },
      },
    );
  };

  const useAddTeam = () => {
    const addTeam = (payload: AddTeam) => axios
      .post(`${apiUrlV3}team`, [payload])
      .then((res) => res.data);

    return useMutation(
      addTeam,
      {
        mutationKey: ['mutation-add-team'],
        onSuccess: () => {
          snackbarService.success('Successfully added new team!');
        },
        onError: (error) => {
          snackbarService.error("Can't add team. Please, try later.");
          console.error("Can't add team:", error);
        },
      },
    );
  };

  const useDeleteTeam = () => {
    const deleteTeam = (teamId: string) => axios
      .delete(`${apiUrlV3}team/${teamId}`);

    return useMutation(
      deleteTeam,
      {
        mutationKey: ['mutation-delete-team'],
        onSuccess: () => {
          snackbarService.success('Successfully deleted!');
        },
        onError: (error) => {
          snackbarService.error("Can't delete team. Please, try later.");
          console.error("Can't delete team:", error);
        },
      },
    );
  };

  const SearchTeamsBySchool = (payload: SearchTeamsBySchoolPayload) => {
    return useQuery(
      'query-teams-by-school-name',
      () => axios.post(
        `${apiUrlV4}team/getTeamsByLevel`,
        { ...payload },
      ).then((res) => {
        return res.data;
      }),
    );
  };

  return {
    GetTeamsPaginated,
    useGetTeamsBySchools,
    useGetTeams,
    useAddTeam,
    useDeleteTeam,
    SearchTeamsBySchool,
  };
};
