import { GetTeams } from 'src/services/interfaces/Teams.types';

import { TBD_OPTION } from '../constants/constants';

type FetchTeams = {
  schoolValue: string,
  watchGenderValue: string,
  watchLevelValue: string,
  watchSportValue: string,
  getTeams: (payload: GetTeams) => void,
  resetTeamData: () => void,
};

export const fetchTeams = (props: FetchTeams) => {
  const {
    schoolValue,
    watchGenderValue,
    watchLevelValue,
    watchSportValue,
    getTeams,
    resetTeamData,
  } = props;

  if (watchGenderValue && watchLevelValue && watchSportValue && (schoolValue !== TBD_OPTION.value && schoolValue)) {
    const payload = {
      school_id: schoolValue,
      gender_id: watchGenderValue,
      grade_list: [watchLevelValue],
      sport_list: [watchSportValue],
    };

    getTeams(payload);
  } else {
    resetTeamData();
  }
};
