/* eslint-disable */
import React, { useEffect } from 'react';

import CloseIcon from 'src/assets/images/menu-icons/close.svg';
import SuccessIcon from 'src/assets/images/menu-icons/done-icon.png';
import ErrorIcon from 'src/assets/images/menu-icons/fail-icon.png';
import Button from 'src/components/common/button/CustomButton';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';

import styles from './MessageModal.module.css';

const success = 'success';

// Component to show Error and Success Modal
// *type - can take values "error"/"success"
// *message - message to show in modal (string or JSX element)
// *closeFn - function to close modal
// *timeout(optional) - time in ms after which modal window must closed automatically
export const MessageModal = (props) => {
  const {
    type, message, timeout, closeFn,
  } = props;
  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        (() => closeFn)()();
      }, timeout);
    }
  }, [timeout]);

  return (
    <ModalWindow isOpen className={styles.modal}>
      <Button version="icon" onClick={closeFn} className={styles.closeIcon}>
        <img
          src={CloseIcon}
          alt="close"
        />
      </Button>
      <img
        src={type === success ? SuccessIcon : ErrorIcon}
        alt={`${type}-icon`}
      />
      {type === success && <span className={styles.msgSuccess}>Success!</span>}
      <span className={styles.msgProps}>{message}</span>
    </ModalWindow>
  );
};
