import { RegularScheduleField } from 'src/components/customer/schedules/components/regular-schedules/regular-schedule-form/RegularScheduleForm.types';
import { messages } from 'src/constants';
import { selectDefaultSchema } from 'src/schemas';
import {
  object, string, date,
} from 'yup';

import { TBD_OPTION } from './constants/constants';

const { FILL_OUT_FIELD } = messages;

type School = {
  value: string,
};

const validateSchools = (isSuperAdminOrBroadcaster: boolean, homeSchool: School, awaySchool: School) => {
  if (isSuperAdminOrBroadcaster) {
    return true;
  }

  const areSchoolsNull = (homeSchool === null) && (awaySchool === null);
  const areSchoolsTBDOrNull = ((homeSchool?.value === TBD_OPTION.value) && (awaySchool?.value === TBD_OPTION.value))
    || (!homeSchool?.value && (awaySchool?.value === TBD_OPTION.value)) || (!awaySchool?.value && (homeSchool?.value === TBD_OPTION.value)) || areSchoolsNull;

  return !areSchoolsTBDOrNull;
};

export const regularScheduleFormSchema = (isSuperAdmin: boolean, isBroadcaster: boolean) => {
  return object().shape({
    date: date().required(FILL_OUT_FIELD),
    country: isSuperAdmin ? selectDefaultSchema() : object().nullable().notRequired(),
    sport: selectDefaultSchema(),
    level: selectDefaultSchema(),
    gender: selectDefaultSchema(),
    liveStreamURL: string().url('Please enter a valid URL'),
    description: string()
      .when('description', {
        is: (value: string) => !!value,
        then: string().max(300, 'Exceeded 300-character limit'),
      }),
    homeState: selectDefaultSchema({ isRequired: false }).when('awayState', {
      is: (awayState: RegularScheduleField) => !awayState?.value,
      then: selectDefaultSchema({ msg: 'At least one state is required, Home or Away' }),
    }).nullable(),
    homeSchool: selectDefaultSchema({ isRequired: false }).test(
      'schoolTBD',
      'At least one school is required, Home or Away',
      (_, context) => {
        const { homeSchool } = context.parent;
        const { awaySchool } = context.parent;

        return validateSchools(isSuperAdmin || isBroadcaster, homeSchool, awaySchool);
      },
    ),
    homeTeam: selectDefaultSchema({ isRequired: false }).when('awayTeam', {
      is: (awayTeam: RegularScheduleField) => !awayTeam?.value,
      then: selectDefaultSchema({ msg: 'At least one team is required, Home or Away' }),
    }),
    awayState: selectDefaultSchema({ isRequired: false }).when('homeState', {
      is: (homeState: RegularScheduleField) => !homeState?.value,
      then: selectDefaultSchema({ msg: 'At least one state is required, Home or Away' }),
    }).nullable(),
    awaySchool: selectDefaultSchema({ isRequired: false }).test(
      'schoolTBD',
      'At least one school is required, Home or Away',
      (_, context) => {
        const { homeSchool } = context.parent;
        const { awaySchool } = context.parent;

        return validateSchools(isSuperAdmin || isBroadcaster, homeSchool, awaySchool);
      },
    ),
    awayTeam: selectDefaultSchema({ isRequired: false }).when('homeTeam', {
      is: (homeTeam: RegularScheduleField) => !homeTeam?.value,
      then: selectDefaultSchema({ msg: 'At least one team is required, Home or Away' }),
    }),
  }, [
    ['description', 'description'],
    ['homeState', 'awayState'],
    ['homeSchool', 'awaySchool'],
    ['homeTeam', 'awayTeam'],
  ]);
};
