import { SchedulePayload } from 'src/services/interfaces/Schedules.interface';
import { dateWithoutTimezone } from 'src/util/dateWithoutTimezone';

import { OTHER_OPTION, TBD_OPTION } from '../constants/constants';
import { ScheduleToSave } from '../RegularScheduleForm.types';
import { getCompetitorName } from './getCompetitorName';

export const formatScheduleToSave = (form: ScheduleToSave) => {
  const {
    _id,
    email,
    date,
    homeSchool,
    homeTeam,
    homeTeamOther,
    awaySchool,
    awayTeam,
    awayTeamOther,
    where,
    country,
    homeState,
    homeAssociation,
    awayState,
    awayAssociation,
    sport,
    gender,
    level,
    facility,
    tournament,
    liveStreamURL,
    description,
    inactive,
    cancelled,
    postponed,
  } = form;

  const homeCompetitorName = getCompetitorName(homeSchool, homeTeam, homeTeamOther);
  const awayCompetitorName = getCompetitorName(awaySchool, awayTeam, awayTeamOther);

  const payload: SchedulePayload = {
    _id,
    email,

    offset: new Date(date).getTimezoneOffset(),
    game_start_ts: dateWithoutTimezone(date),

    name: `${awayCompetitorName} at ${homeCompetitorName}`,
    where, // place where game is taking place 'Home' | 'Away'

    country,

    state: homeState?.value ? homeState.value : '',
    selected_home_association_id: homeAssociation?.value ? homeAssociation.value : null,

    away_state: awayState?.value ? awayState.value : '',
    selected_away_association_id: awayAssociation?.value ? awayAssociation.value : null,

    home_name: homeSchool?.value !== TBD_OPTION.value
      ? homeSchool?.display_name
        ? homeSchool?.display_name
        : homeSchool?.label
      : '',
    away_name: awaySchool?.value !== TBD_OPTION.value
      ? awaySchool?.display_name
        ? awaySchool?.display_name
        : awaySchool?.label
      : '',
    home_school_id: homeSchool?.value !== TBD_OPTION.value ? homeSchool?.value : null,
    away_school_id: awaySchool?.value !== TBD_OPTION.value ? awaySchool?.value : null,

    home_team_name: (homeTeam?.value === OTHER_OPTION.value ? homeTeamOther || TBD_OPTION.label : homeTeam?.label) ?? null,
    away_team_name: (awayTeam?.value === OTHER_OPTION.value ? awayTeamOther || TBD_OPTION.label : awayTeam?.label) ?? null,

    home_team_id: homeTeam?.value !== OTHER_OPTION.value ? homeTeam?.value : null,
    away_team_id: awayTeam?.value !== OTHER_OPTION.value ? awayTeam?.value : null,

    facility_mongo_id: facility?.value,

    sport_mongo_id: sport.value,
    gender_id: gender.value,
    grade_id: level.value,

    tournament_id: tournament?.value || null,

    livestream: liveStreamURL || '',
    type: 'event',
    description: description || '',

    inactive: inactive || false,
    cancelled: cancelled || false,
    postponed: postponed || false,
  };

  return payload;
};
