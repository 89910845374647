import { ColorModalEnums } from 'src/enums/ColorModalEnums';

const { BLUE } = ColorModalEnums;

const confirmationModalInitialState = {
  open: false,
  type: BLUE,
  title: '',
  message: '',
  approveFn: null,
  closeFn: null,
  cancelFn: null,
  approveTitle: 'Yes',
  cancelTitle: 'No',
  hideCancelBtn: false,
};

export default confirmationModalInitialState;
