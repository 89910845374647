import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV3, apiUrlV4 } from 'src/services/constants/api-versions';
import {
  FacilitiesRes,
  GetFacilities,
  FacilityTypesRes,
  FacilityType,
  GetFacilitiesBySchoolIdPayload,
  GetFacilitiesBySchoolIdRes,
} from 'src/services/interfaces/Facility.types';
import { ErrorRes } from 'src/types';

export const FacilityService = (axios: AxiosInstance) => {
  const useGetAllFacilities = () => {
    return useQuery(
      'query-facilities',
      () => axios.get(`${apiUrlV3}facility/getAll/`)
        .then((res) => {
          return res.data;
        }).catch((err) => {
          console.error(err);
          snackbarService.error('Something went wrong while loading all facilities. Please try again later.');
        }),
      {
        cacheTime: 1000 * 60 * 60,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );
  };

  const useGetFacilitiesBySchoolId = (payload: GetFacilitiesBySchoolIdPayload, enabled: boolean) => {
    const getFacilitiesBySchoolId = () => axios
      .post(`${apiUrlV4}facility/get-all`, payload)
      .then((res) => {
        const resData: GetFacilitiesBySchoolIdRes = res.data;
        return resData;
      });

    return useQuery({
      queryKey: ['query-get-facilities-by-school-id', payload],
      queryFn: getFacilitiesBySchoolId,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
      enabled: !!enabled,
    });
  };

  const useGetAllFacilityTypes = (isOrderFacility: boolean) => {
    const getAllFacilityTypes = () => axios
      .get(`${apiUrlV4}facility-type${isOrderFacility ? '?is_order_facility=true' : ''}`)
      .then((res) => {
        const resData: FacilityTypesRes[] = res?.data?.data?.map((t: FacilityType) => {
          return {
            label: t.name,
            value: t._id,
          };
        });

        return resData;
      });

    return useQuery(
      isOrderFacility ? 'query-facility-types' : 'query-facility-types-order-device',
      getAllFacilityTypes,
      {
        onError: (error) => {
          console.error('Error in get facility types:', error);
          snackbarService.error("Can't get facility types. Please, try later.");
        },
        cacheTime: 1000 * 60 * 60,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );
  };

  const useGetFacilities = (payload: GetFacilities) => {
    const getFacilities = () => axios
      .post(`${apiUrlV4}facility/drop-down`, payload)
      .then((res) => {
        const resData: FacilitiesRes = res.data;
        return resData;
      });

    return useQuery({
      queryKey: ['get-facility-drop-down', payload],
      queryFn: getFacilities,
      initialData: () => ({
        user_facilities: [],
        all_facilities: [],
      }),
      onError: (error) => {
        snackbarService.error("Can't get facilities. Please, try later.");
        console.error("Can't get facilities:", error);
      },
      refetchOnWindowFocus: false,
    });
  };

  const useGetFacilitiesMutation = () => {
    const getFacilities = (payload: GetFacilities) => axios
      .post(`${apiUrlV4}facility/drop-down`, payload)
      .then((res) => {
        const resData: FacilitiesRes = res.data;
        return resData;
      });

    return useMutation({
      mutationKey: ['mutation-get-facilities'],
      mutationFn: getFacilities,
      onError: (error) => {
        snackbarService.error("Can't get facilities. Please, try later.");
        console.error("Can't get facilities:", error);
      },
    });
  };

  return {
    useGetFacilitiesBySchoolId,
    useGetAllFacilities,
    useGetAllFacilityTypes,
    useGetFacilities,
    useGetFacilitiesMutation,
  };
};
