import { AxiosInstance } from 'axios';

import { apiUrlV3 } from '../constants/api-versions';

export const GradeService = (axios:AxiosInstance) => {
  /**
   * Get all grades
   * Save grades in REDUX store
   */
  const getAllTeamGrades = () => {
    return axios.get(`${apiUrlV3}team_grades`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  return {
    getAllTeamGrades,
  };
};
