import { AxiosInstance } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';
import { capitalize } from 'src/util/stringFormat';

type CartPayload = {
  subscription_id: string,
  facility_type_id: string,
  facility_type: string,
  scoreboard_controllers: {
    scoreboard_controller_id: string,
    model_name: string,
    other_sport: string,
    sports: string[],
  }[]
};

export type CreateDeviceOrderPayload = {
  user_first_name: string,
  user_last_name: string,
  user_email: string,
  user_phone: string,
  organization_type: string,
  organization_name: string,
  shipping_address?: {
    address: string,
    address_2: string,
    city: string,
    state: string,
    country: string,
    zip: string,
  }
  cart?: CartPayload,
  cart_total?: number,
  billing_address?: {
    address: string,
    address_2: string,
    city: string,
    state: string,
    country: string,
    zip: string,
  },
  stripe_token?: any,
  promo_code?: string,
  pay_later?: boolean,
  is_order_placed?: boolean,
  terms_agreed?: boolean,
};

export const SubscriptionsService = (axios: AxiosInstance) => {
  const GetSubscriptions = () => {
    const getSubscriptions = () => axios.get(
      `${apiUrlV4}subscriptions`,
    ).then((response) => {
      return response.data.data;
    });

    return useQuery(
      'query-subscriptions',
      getSubscriptions,
      {
        onError: (error) => {
          snackbarService.error('Error getting subscriptions. Try again later.');
          console.error('Error when trying to get the subscriptions:', error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  const GetOrganizationTypes = () => {
    const getOrganizationTypes = () => axios.get(
      `${apiUrlV4}organization-types`,
    ).then((response) => {
      return response.data.data.filter((o) => o.name.toLowerCase() !== 'other')
        .map((organization) => ({ label: organization.name, value: organization._id }))
        .concat({ label: 'Other', value: 'other' });
    });

    return useQuery(
      'query-organization-types',
      getOrganizationTypes,
      {
        onError: (error) => {
          snackbarService.error('Error getting organization types. Try again later.');
          console.error('Error when trying to get the organization types:', error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  const GetScoreboardControllers = () => {
    const getScoreboardControllers = () => axios.get(
      `${apiUrlV4}scoreboard-controllers`,
    ).then((response) => {
      return response.data.data.map((controller) => ({
        label: controller.name,
        value: controller._id,
        models: controller.models.map((model) => ({
          label: model.name,
          value: model.name,
          sports: model.sports.map((sport) => ({
            label: capitalize(sport),
            value: sport,
          }))
            .concat({ label: 'Other', value: 'other' }),
        })),
      }));
    });

    return useQuery(
      'query-scoreboard-controllers',
      getScoreboardControllers,
      {
        onError: (error) => {
          snackbarService.error('Error getting scoreboard controllers. Try again later.');
          console.error('Error when trying to get the scoreboard controllers:', error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  const CreatePaymentIntent = () => {
    const createPaymentIntent = (payload) => axios.post(`${apiUrlV4}device-order/payment-intent`, payload);

    return useMutation(
      'mutation-payment-intent',
      createPaymentIntent,
      {
        onError: (error) => {
          console.error('Error in create payment intent:', error);
          snackbarService.error("Can't create payment intent. Please, try later.");
        },
      },
    );
  };

  const ValidatePromoCode = () => {
    const validatePromoCode = (payload) => axios.post(`${apiUrlV4}device-order/promo-code/validate`, payload)
      .then((res) => {
        if (res.data.is_valid_code) {
          snackbarService.success(res.data.message);
        }
        return res.data;
      });

    return useMutation(
      'mutation-validate-promo-code',
      validatePromoCode,
      {
        onError: (error) => {
          console.error('Error validating promo code:', error);
          snackbarService.error("Can't validate promo code. Please, try later.");
        },
      },
    );
  };

  const CreateDeviceOrder = () => {
    const createDeviceOrder = (payload: CreateDeviceOrderPayload) => axios.post(`${apiUrlV4}device-order`, payload)
      .then((res) => res.data);

    return useMutation(
      'mutation-create-device-order',
      createDeviceOrder,
      {
        onError: (error) => {
          console.error('Error in create device order:', error);
          snackbarService.error("Can't create device order. Please, try later.");
        },
      },
    );
  };

  return {
    GetSubscriptions,
    GetOrganizationTypes,
    GetScoreboardControllers,
    CreatePaymentIntent,
    ValidatePromoCode,
    CreateDeviceOrder,
  };
};
