import { TeamEnums } from 'src/enums/TeamEnums';

const { HOME } = TeamEnums;

type GenerateTeamsPayload = {
  type: TeamEnums,
  watchHomeSchoolValue: string,
  watchAwaySchoolValue: string,
  watchGenderValue: string,
  watchLevelValue: string,
  watchSportValue: string,
};

export const generateTeamsPayload = (props: GenerateTeamsPayload) => {
  const {
    type,
    watchHomeSchoolValue,
    watchAwaySchoolValue,
    watchGenderValue,
    watchLevelValue,
    watchSportValue,
  } = props;

  return {
    school_id: type === HOME ? watchHomeSchoolValue : watchAwaySchoolValue,
    gender_id: watchGenderValue,
    grade_list: [watchLevelValue],
    sport_list: [watchSportValue],
  };
};
