import { useEffect } from 'react';

import { TeamRes } from 'src/services/interfaces/Teams.types';
import { SetValue } from 'src/types';

import { OTHER_OPTION } from '../constants/constants';
import { RegularScheduleFormData } from '../RegularScheduleForm.types';

type TeamOptions = {
  isDirty: boolean,
  teamOptions: TeamRes,
  fieldName: 'homeTeam' | 'awayTeam',
  setValue: SetValue<RegularScheduleFormData>,
};

const useTeamOptions = (props: TeamOptions) => {
  const {
    isDirty,
    teamOptions,
    fieldName,
    setValue,
  } = props;

  useEffect(() => {
    if (isDirty) {
      setValue(
        fieldName,
        (teamOptions.data[0].value !== OTHER_OPTION.value) ? teamOptions.data[0] : null,
        { shouldValidate: teamOptions.data[0].value !== OTHER_OPTION.value },
      );
    }
  }, [teamOptions.data?.length]);
};

export default useTeamOptions;
