export enum PartnerEnums {
  VNN = 'vnn',
  DRAGONFLY = 'dragonfly',
  RANKONE = 'rankone',
  SCORESTREAM = 'scorestream',
  RAPIDREPLAY = 'rapidreplay',
  BLUEFRAME = 'blueframe',
  PRESTO = 'presto',
  SIDEARM = 'sidearm',
  RST = 'rst',
  MASCOTMEDIA = 'mascotmedia',
}
