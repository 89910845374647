import React, { lazy } from 'react';

import { AuthLayout } from 'src/components/auth/auth-layout/AuthLayout';
import WaitingRoom from 'src/components/auth/waiting-room/WaitingRoom';
import CustomerLayout from 'src/components/customer/CustomerLayout';
import RegularScheduleForm from 'src/components/customer/schedules/components/regular-schedules/regular-schedule-form/RegularScheduleForm';

const SignUp = lazy(() => import('src/components/auth/sign-up/SignUp'));
const SignIn = lazy(() => import('src/components/auth/sign-in/SignIn'));
const ForgotPassword = lazy(() => import('src/components/auth/forgot-password/ForgotPassword'));
const ResendRequest = lazy(() => import('src/components/auth/resend-request/ResendRequest'));
const ResetPassword = lazy(() => import('src/components/auth/reset-password/ResetPassword'));
const UserManagement = lazy(() => import('src/components/customer/user-management/UserManagement'));
const NotFound = lazy(() => import('src/components/common/other-pages/NotFound'));
const Profile = lazy(() => import('src/components/customer/profile/Profile'));
const GameDay = lazy(() => import('src/components/customer/game-day/GameDay'));
const AboutFacility = lazy(() => import('src/components/customer/facilities/aboutFacility/AboutFacility'));
// const EditGameModal = lazy(() => import('src/components/customer/games/EditGameModal/EditGameModal'));
const SchoolForm = lazy(() => import('src/components/customer/schools/components/schoolForm/SchoolForm'));
const RequestSchool = lazy(() => import('src/components/customer/schools/components/requestSchool/RequestSchool'));
const SchoolDetails = lazy(() => import('src/components/customer/schools/components/details-components/SchoolDetails'));
const AboutTeam = lazy(() => import('src/components/customer/teams/components/aboutTeam/AboutTeam'));
const AddUserModal = lazy(() => import('src/components/customer/users/add-user/AddUserModal'));
const Schedules = lazy(() => import('src/components/customer/schedules/Schedules'));
const Facilities = lazy(() => import('src/components/customer/facilities/Facilities'));
const Devices = lazy(() => import('src/components/customer/devices/Devices'));
const DeviceTabs = lazy(() => import('src/components/customer/devices/components/tabs/Tabs'));
const DeviceDetails = lazy(() => import('src/components/customer/devices/components/tabs/components/details/Details'));
const DeviceWifiSetup = lazy(() => import('src/components/customer/devices/components/tabs/components/wifiSetup/WifiSetup'));
const DeviceScoreboard = lazy(() => import('src/components/customer/devices/components/tabs/components/scoreboard/Scoreboard'));
const DeviceNativeVideo = lazy(() => import('src/components/customer/devices/components/tabs/components/nativeVideo/NativeVideo'));
const GamesAdmin = lazy(() => import('src/components/customer/games-admin/GamesAdmin'));
const Schools = lazy(() => import('src/components/customer/schools/Schools'));
const Teams = lazy(() => import('src/components/customer/teams/Teams'));
const OverlayBuilder = lazy(() => import('src/components/customer/overlay-builder/OverlayBuilder'));
const OverlayBuilderSecondStep = lazy(() => import('src/components/customer/overlay-builder/components/overlayBuilderSecondStep/OverlayBuilderSecondStep'));
const Twitter = lazy(() => import ('src/components/customer/twitter/twitter'));
const TwitterIndex = lazy(() => import('src/components/customer/twitter'));
const PitchCount = lazy(() => import('src/components/customer/pitch-count/PitchCount'));
// const PastGames = lazy(() => import('src/components/customer/games/Games'));
const Users = lazy(() => import('src/components/customer/users/Users'));
const Notification = lazy(() => import('src/components/customer/notification/Notification'));
const DeviceOrder = lazy(() => import('src/components/customer/device-order/DeviceOrder'));

export const useRouteConfig = () => {

  // If change '/' to '/*' or '*', the NotFound page doesn't work
  return [
    {
      path: '/',
      element: <CustomerLayout><GameDay /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/sign-in',
      element: <AuthLayout> <SignIn /> </AuthLayout>,
      protected: false,
    },
    {
      path: '/sign-up',
      element: <AuthLayout> <SignUp /> </AuthLayout>,
      protected: false,
    },
    {
      path: '/forgot-password',
      element: <AuthLayout><ForgotPassword /></AuthLayout>,
      protected: false,
    },
    {
      path: '/reset-password/:email/:code',
      element: <AuthLayout><ResetPassword /></AuthLayout>,
      protected: false,
    },
    {
      path: '/resend-request',
      element: <AuthLayout><ResendRequest /></AuthLayout>,
      protected: false,
    },
    {
      path: '/waiting-room',
      element: <WaitingRoom />,
      protected: false,
    },
    {
      path: '/device-order/*',
      element: <DeviceOrder />,
      protected: false,
    },
    {
      path: '/schedules/*',
      element: <CustomerLayout><Schedules /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/schedules/add/*',
      element: <CustomerLayout><RegularScheduleForm /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/schedules/:scheduleId/*',
      element: <CustomerLayout><RegularScheduleForm /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/facilities/*',
      element: <CustomerLayout><Facilities /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/facilities/:facilityId/*',
      element: <CustomerLayout><AboutFacility /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/schools/*',
      element: <CustomerLayout><Schools /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/schools/add/*',
      element: <CustomerLayout><SchoolForm /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/schools/request/*',
      element: <CustomerLayout><RequestSchool /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/schools/:schoolId/*',
      element: <CustomerLayout><SchoolDetails /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/schools/:schoolId/edit/*',
      element: <CustomerLayout><SchoolForm /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/teams/*',
      element: <CustomerLayout><Teams /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/teams/:teamId/*',
      element: <CustomerLayout><AboutTeam /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/devices/*',
      element: <CustomerLayout><Devices /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/devices/:deviceId/details/*',
      element: <CustomerLayout><DeviceTabs><DeviceDetails /></DeviceTabs></CustomerLayout>,
      protected: true,
    },
    {
      path: '/devices/:deviceId/wifi-setup/*',
      element: <CustomerLayout><DeviceTabs><DeviceWifiSetup isDeviceOnline /></DeviceTabs></CustomerLayout>,
      protected: true,
    },
    {
      path: '/devices/:deviceId/scoreboard/*',
      element: <CustomerLayout><DeviceTabs><DeviceScoreboard /></DeviceTabs></CustomerLayout>,
      protected: true,
    },
    {
      path: '/devices/:deviceId/native-video/*',
      element: <CustomerLayout><DeviceTabs><DeviceNativeVideo /></DeviceTabs></CustomerLayout>,
      protected: true,
    },
    {
      path: '/pitch-count/*',
      element: <CustomerLayout><PitchCount /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/overlay-builder/*',
      element: <CustomerLayout><OverlayBuilder /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/overlay-builder/preview/*',
      element: <CustomerLayout><OverlayBuilderSecondStep /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/tweetScores/*',
      element: <CustomerLayout><Twitter /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/twitter/:authStatus/:message/:error',
      element: <CustomerLayout><TwitterIndex /></CustomerLayout>,
      protected: true,
    },
    // {
    //   path: '/games/*',
    //   element: <CustomerLayout><PastGames /></CustomerLayout>,
    //   protected: true,
    // },
    // {
    //   path: '/games/:scheduleId/*',
    //   element: <CustomerLayout><EditGameModal /></CustomerLayout>,
    //   protected: true,
    // },
    {
      path: '/games-admin/*',
      element: <CustomerLayout><GamesAdmin /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/users/*',
      element: <CustomerLayout><Users /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/user-management/*',
      element: <CustomerLayout><UserManagement /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/users/:id/*',
      element: <CustomerLayout><Profile editProfile /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/invite-user/*',
      element: <CustomerLayout><AddUserModal /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/add-user/*',
      element: <CustomerLayout><AddUserModal /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/profile/*',
      element: <CustomerLayout><Profile /></CustomerLayout>,
      protected: true,
    },
    {
      path: '/notifications/*',
      element: <CustomerLayout><Notification /></CustomerLayout>,
    },
    {
      path: '*' || '/not-found',
      element: <CustomerLayout><NotFound /></CustomerLayout>,
      protected: true,
    },
  ];
};
