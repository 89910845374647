import { PartnerEnums } from 'src//enums/PartnerEnums';

import { FormatScheduleToEdit } from '../RegularScheduleForm.types';

const { RANKONE } = PartnerEnums;

export const getDisableStatus = (schedule: FormatScheduleToEdit) => {
  if (!schedule) return false;
  if (schedule.finalized) return true;
  if (schedule?.mapping.find((s) => s.name === RANKONE)) return true;

  return !!schedule.live;
};
