export type FacilityFormField = {
  value: string,
  label: string,
  deviceSerial?: string,
  assignedSchool?: string,
  rankoneId?: string,
};

export type Facility = {
  _id: string,
  name: string,
  device_serial?: string,
  school_id: string,
  rankone_id?: string,
};

export const getFacility = (facility: Facility) => {
  return {
    label: `${facility.name}${facility.device_serial
      ? ` #${facility.device_serial}`
      : ''}`,
    value: facility._id,
    deviceSerial: facility?.device_serial,
    assignedSchool: facility?.school_id,
    rankoneId: facility?.rankone_id,
  };
};
