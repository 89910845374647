import React, { memo, Suspense } from 'react';

import logoIcon from 'src/assets/images/logo/logo-icon-new.svg';
import logoText from 'src/assets/images/logo/logo-text-new.svg';
import Spinner from 'src/components/common/spinner/Spinner';

import styles from './AuthLayout.module.css';

// eslint-disable-next-line react/display-name
export const AuthLayout = memo(({ children }) => {

  return (
    <div className={styles.authContainer}>
      <div className={styles.authMainWrapper}>
        <div className={styles.leftWrapper}>
          <div className={styles.leftContent}>
            <Suspense fallback={<Spinner />}>
              {children}
            </Suspense>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.rightContent}>
            <img className={styles.logoIcon} src={logoIcon} alt="Scorebird_logo_icon" />
            <div>
              <span className={styles.text}>Welcome to</span>
              <img className={styles.logoText} src={logoText} alt="Scorebird_logo_text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
