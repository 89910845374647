/**
 * Returns the correct level name for the given level.
 * Uppercase first letter in regular levels.
 * Uppercase short names to full upper case.
* */
import { capitalizeAllFirstLetters } from './stringFormat';

export const getCorrectLevelName = (level: string): string => {
  if (!level) return '--';

  if (level.toLocaleLowerCase() === 'junior varsity') { return 'JV'; }

  if (level.length > 4) {
    return capitalizeAllFirstLetters(level);
  }
  return level.toUpperCase();
};
