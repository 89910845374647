import React from 'react';

import { components, ClearIndicatorProps } from 'react-select';
import Tooltip from 'src/components/common/tooltip/Tooltip';

type ClearIndicatorValues = ClearIndicatorProps & {
  toolTipClearButton: string,
};

const ClearIndicator = (props: ClearIndicatorValues) => {
  const { toolTipClearButton, children } = props;

  return (
    <Tooltip content={toolTipClearButton} disabled={!toolTipClearButton}>
      <div>
        <components.ClearIndicator {...props}>
          {children}
        </components.ClearIndicator>
      </div>
    </Tooltip>
  );
};

export default ClearIndicator;
