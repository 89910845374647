import React from 'react';

import cn from 'classnames';
import ReactModal from 'react-modal';
import { RemoveScroll } from 'react-remove-scroll';

import styles from './ModalWindow.module.css';
import { ModalWindowProps } from './ModalWindow.props';

const ModalWindow = ({
  isOpen, overlayClassName, className, children,
}: ModalWindowProps) => {

  return (
    <RemoveScroll>
      <ReactModal
        isOpen={isOpen}
        appElement={document.getElementById('app')}
        overlayClassName={cn(styles.basicOverlay, overlayClassName)}
        className={`${styles.basicClassModal} ${className}`}
      >
        {children}
      </ReactModal>
    </RemoveScroll>
  );
};

export default ModalWindow;
