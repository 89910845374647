import axios from 'axios';

function setAuthToken(token) {
  if (token) {
    axios.defaults.headers.common.Authorization = `${token}`;
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
}

export default setAuthToken;
