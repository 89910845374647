import React, {
  Suspense, useEffect, useMemo, useState, useContext, useCallback,
} from 'react';

import { ErrorBoundary } from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route, Routes, useNavigate,
} from 'react-router-dom';
import PageCrash from 'src/components/common/other-pages/PageCrash';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import Spinner from 'src/components/common/spinner/Spinner';
import { CustomerLayoutProps } from 'src/components/customer/CustomerLayout.props';
import countries from 'src/constants/countries';
import statesByCountry from 'src/constants/statesByCountry';
import { SocketContext } from 'src/context/socket';
import { SchoolEnums } from 'src/enums/SchoolEnums';
import { useFetchGenders } from 'src/hooks/api-hooks/useFetchGenders';
import { useFetchGrades } from 'src/hooks/api-hooks/useFetchGrades';
import { useFetchSchools } from 'src/hooks/api-hooks/useFetchSchools';
import { useFetchSports } from 'src/hooks/api-hooks/useFetchSports';
import api from 'src/services/api';
import { UserLogOut } from 'src/services/auth';
import { SchoolRequestEnums } from 'src/services/enums/SchoolRequestEnums';
import ScorebirdService from 'src/services/scorebird.service';
import { RootState } from 'src/store/types/rootStateTypes';

import Header from './aside-menu/Header';
import Menu from './aside-menu/Menu';
import styles from './CustomerLayout.module.css';

const { ALL_SCHOOLS } = SchoolEnums;
const { SCHOOL_REQUEST } = SchoolRequestEnums;

const CustomerLayout = ({ children, hideUi = false }: CustomerLayoutProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sbs = useMemo(() => new ScorebirdService(), []);
  const socket = useContext(SocketContext);

  const { currentUser, userEmail } = useSelector((state:RootState) => state.currentUser);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const [associations, setAssociations] = useState(null);

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolsOptions, setSchoolsOptions] = useState([]);
  const { GetUserDataByEmail } = api.userService();
  const { mutateAsync: getUserDataByEmail } = GetUserDataByEmail();
  const { isFetching: fetchingSports } = useFetchSports();
  const { isFetching: fetchingGrades } = useFetchGrades();
  const { isLoading: fetchingGenders } = useFetchGenders();

  // ############################# QUERIES #####################################
  const {
    schools,
    isFetching,
    fetchSchools,
  } = useFetchSchools({
    query: {
      country: selectedCountry?.value,
      state: selectedState?.value,
      association: selectedAssociation?.value,
    },
    addAllSchools: true,
  });
  // ###########################################################################

  useEffect(() => {
    const defaultCountry = countries
      .find((c) => c.value === (sessionStorage.getItem('selected_country') || (currentUser?.super_admin && ('US'))));
    setSelectedCountry(defaultCountry);

    const defaultState = statesByCountry[(sessionStorage.getItem('selected_country') || (currentUser?.super_admin && ('US')))]
      ?.find((state) => state.value === sessionStorage.getItem('selected_state'));
    setSelectedState(defaultState);
  }, [currentUser]);

  // Get current User
  useEffect(() => {
    if (!currentUser || !currentUser?.super_admin) {
      getUserDataByEmail(userEmail).then((response) => {
        if (response.success) {
          try {
            // @ts-ignore
            window.$crisp?.push(['set', 'user:email', [response.data.email]]);
            // @ts-ignore
            window.$crisp?.push(['set', 'user:nickname', [` ${response.data.first_name} ${response.data.last_name}`]]);
          } catch (e) {
            console.group('Error in Crisp set email or nickname');
            console.log('response.data:', response?.data);
            console.log(e);
            console.groupEnd();
          }
          dispatch({ type: 'currentUser/set', payload: response.data });
          if (!response.data.selected_schools) {
            navigate('/sign-up', { state: { email: response.data.email, step: 1 } });
          }
          if (!response.data.super_admin) {
            fetchSchools(response.data.email);
          }
        } else {
          snackbarService.error('User profile can not be loaded, please try again later.');
          setTimeout(() => {
            dispatch({ type: 'currentUser/remove' });
            UserLogOut();
          }, 2000);
        }
      });
    }
    // Get associations
    if (!associations) {
      sbs.getAssociationsList().then((associationsList) => {
        const associationsOptions = associationsList.map((item) => ({
          label: (
            <>
              {item.name.toUpperCase()}
              {item.logo && <img style={{ maxHeight: 15, margin: '0 5px' }} src={item.logo} alt="i" />}
            </>
          ),
          value: item.name,
          data: item,
        }));
        setAssociations(associationsOptions);
        setSelectedAssociation(associationsOptions.find((a) => a.value === sessionStorage.getItem('selected_group')));
        dispatch({
          type: 'association/setAssociationsList',
          payload: associationsOptions,
        });
      });
    }
  }, []);

  // Organize schools on the menu for different admins
  useEffect(() => {
    let options;

    const getStates = schools.map((s) => s.state);
    const filterStates = [...new Set(getStates)];

    if (currentUser?.association_admin) {
      options = [{
        label: `All ${currentUser.association_admin.toUpperCase()} Schools`,
        value: [...currentUser.selected_schools],
        state: filterStates,
      }, ...schools];
    } else if (!currentUser?.super_admin && !currentUser?.association_admin) {
      options = [{
        label: ALL_SCHOOLS,
        value: schools.map((s) => s.value),
        state: filterStates,
      }, ...schools];
    } else {
      options = schools;
    }

    setSchoolsOptions(options);
    setSelectedSchool(options.find((school) => school.value === sessionStorage.getItem('selected_school')) || options[0]);

    dispatch({
      type: 'set_schools_list',
      schools: schools.filter((school) => school.value !== null),
    });
  }, [schools]);

  /* On Country Change */
  useEffect(() => {
    dispatch({
      type: 'currentUser/selectedCountry',
      payload: selectedCountry,
    });
  }, [selectedCountry]);

  /* On State Change */
  useEffect(() => {
    try {
      // @ts-ignore
      window.$crisp?.push(['set', 'session:event', [[['state-changed', { label: 'not selected', value: 'not selected', ...selectedState }, 'blue']]]]);
    } catch (e) {
      console.warn('Crisp state session:event push error:', e);
      console.warn('Crisp state session:event push error:', selectedState);
    }
    dispatch({
      type: 'currentUser/selectedState',
      payload: selectedState,
    });
    setSelectedSchool(schools[0] || null);
    if (!selectedState && !currentUser && currentUser?.super_admin) {
      sessionStorage.removeItem('selected_school');
      sessionStorage.removeItem('selected_group');
      setTimeout(() => {
        setSelectedSchool(null);
        setSelectedAssociation(null);
      }, 0);
    }
    fetchSchools();
  }, [selectedState]);

  /* On Association Change */
  useEffect(() => {
    try {
      // @ts-ignore
      window.$crisp?.push(['set', 'session:event', [[['association-changed', { association: selectedAssociation?.value || 'not selected' }, 'yellow']]]]);
    } catch (e) {
      console.warn('Crisp association session:event push error:', e);
      console.warn('Crisp association session:event push error:', selectedAssociation);
    }
    dispatch({
      type: 'currentUser/selectedAssociation',
      payload: selectedAssociation,
    });
  }, [selectedAssociation]);

  /* On School Change */
  useEffect(() => {
    try {
      // @ts-ignore
      window.$crisp?.push(['set', 'session:event', [[['school-changed', { label: selectedSchool?.label || 'not selected', value: selectedSchool?.value || 'not selected' }, 'green']]]]);
    } catch (e) {
      console.warn('Crisp school session:event push error:', e);
      console.warn('Crisp school session:event push error:', selectedSchool);
    }
    dispatch({
      type: 'currentUser/selectedSchool',
      payload: selectedSchool,
    });
  }, [selectedSchool]);

  const handleSchoolRequestStatus = useCallback(() => {
    // Bug: sometimes currentUser?.email doesn't come from Redux
    fetchSchools(currentUser?.email || userEmail);
  }, []);

  useEffect(() => {
    socket.connect(); // WebSocket runs here
    socket.on(SCHOOL_REQUEST, handleSchoolRequestStatus);

    return () => {
      socket.off(SCHOOL_REQUEST, handleSchoolRequestStatus);
    };
  }, []);

  if (!currentUser) {
    return <div className={styles.mainLoader}><Spinner /></div>;
  }

  if (hideUi) {
    return (children);
  }

  return (
    <>
      <Header />
      <Routes>
        <Route
          path="/*"
          element={(
            <div className={styles.wrapper}>
              <Menu
                onCountrySelect={(country) => {
                  sessionStorage.setItem('selected_country', country?.value);
                  setSelectedCountry(country);

                  setSelectedState(null);
                  setSelectedAssociation(null);
                  setSelectedSchool(null);
                }}
                onStateSelect={(state) => {
                  sessionStorage.setItem('selected_state', state?.value);
                  setSelectedState(state);

                  setSelectedAssociation(null);
                  setSelectedSchool(null);
                }}
                onGroupSelect={(group) => {
                  sessionStorage.setItem('selected_group', group?.value);
                  setSelectedAssociation(group);

                  setSelectedSchool(null);
                }}
                onSchoolSelect={(school) => {
                  sessionStorage.setItem('selected_school', school?.value);
                  setSelectedSchool(school);
                }}
                  // -----------------------------------------------------------
                selectedCountry={selectedCountry || null}
                selectedState={selectedState || null}
                selectedGroup={selectedAssociation || null}
                selectedSchool={selectedSchool || null}
                  // -----------------------------------------------------------
                states={statesByCountry[selectedCountry?.value]}
                associations={associations}
                schools={schoolsOptions}
                schoolsIsLoading={isFetching || fetchingSports || fetchingGrades || fetchingGenders}
              />
              <Suspense fallback={<Spinner />}>
                <ErrorBoundary
                  fallback={<PageCrash />}
                >
                  {children}
                </ErrorBoundary>
              </Suspense>
            </div>
          )}
        />
      </Routes>
    </>
  );
};

export default CustomerLayout;
