import { createReducer } from '@reduxjs/toolkit';
import {
  setContactInfo,
  setBillingInfo,
  setCart,
  setPayVia,
  setPrevCartTotal,
  setCartTotal,
  setCartTotalWithDiscount,
  setDiscount,
  setDiscountType,
  setPromoCode,
  setStripeToken,
  setPayload,
  resetDeviceOrder,
  setOrderId,
} from 'src/store/actions/deviceOrderActions';
import { DeviceOrderState } from 'src/store/types/deviceOrderTypes';

const initialState: DeviceOrderState = {
  currentStep: null,
  contactInfo: null,
  billingInfo: null,
  cart: null,
  payVia: null,
  prevCartTotal: 0,
  currentCartTotal: 0,
  cartTotalWithDiscount: null,
  discount: null,
  discountType: null,
  promoCode: null,
  stripeToken: null,
  payload: null,
  orderId: null,
};

export const deviceOrderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setContactInfo, (state, action) => {
      state.contactInfo = action.payload;
    })
    .addCase(setBillingInfo, (state, action) => {
      state.billingInfo = action.payload;
    })
    .addCase(setCart, (state, action) => {
      state.cart = action.payload;
    })
    .addCase(setPayVia, (state, action) => {
      state.payVia = action.payload;
    })
    .addCase(setPrevCartTotal, (state, action) => {
      state.prevCartTotal = action.payload;
    })
    .addCase(setCartTotal, (state, action) => {
      state.currentCartTotal = action.payload;
    })
    .addCase(setCartTotalWithDiscount, (state, action) => {
      state.cartTotalWithDiscount = action.payload;
    })
    .addCase(setDiscount, (state, action) => {
      state.discount = action.payload;
    })
    .addCase(setDiscountType, (state, action) => {
      state.discountType = action.payload;
    })
    .addCase(setPromoCode, (state, action) => {
      state.promoCode = action.payload;
    })
    .addCase(setStripeToken, (state, action) => {
      state.stripeToken = action.payload;
    })
    .addCase(setPayload, (state, action) => {
      state.payload = action.payload;
    })
    .addCase(resetDeviceOrder, () => {
      return initialState;
    })
    .addCase(setOrderId, (state, action) => {
      state.orderId = action.payload;
    });
});
