import React from 'react';

import cn from 'classnames';
import CloseIcon from 'src/assets/images/menu-icons/close.svg';
import Button from 'src/components/common/button/CustomButton';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';
import { ColorModalEnums } from 'src/enums/ColorModalEnums';

import styles from './TemporaryConfirmationModalNew.module.css';
import { TemporaryConfirmationModalNewProps } from './TemporaryConfirmationModalNew.types';

const { RED, BLUE } = ColorModalEnums;

export const TemporaryConfirmationModalNew = (props: TemporaryConfirmationModalNewProps) => {
  const {
    className,
    type = BLUE,
    approveTitle = 'Yes',
    cancelTitle = 'No',
    approveFn,
    closeFn,
    cancelFn,
    title,
    message,
    hideCancelBtn = false,
  } = props;

  return (
    <ModalWindow isOpen className={cn(styles.modal, className)}>
      <Button version="icon" onClick={closeFn || cancelFn} className={styles.closeIcon}>
        <img
          src={CloseIcon}
          alt="close"
        />
      </Button>

      <span className={styles.title}>{title}</span>

      {message && (
        <span className={styles.message}>{message}</span>
      )}

      <div className={styles.actions}>
        <Button className={cn(styles.approveBlueBtn, type === RED && styles.approveRedBtn)} onClick={approveFn}>{approveTitle}</Button>

        {!hideCancelBtn && (
          <Button className={cn(styles.cancelBlueBtn, type === RED && styles.cancelRedBtn)} onClick={cancelFn}>{cancelTitle}</Button>
        )}
      </div>
    </ModalWindow>
  );
};
