import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';
import {
  GetShadowBySportPayload, GetShadowRes, Shadow, ShadowBySportRes,
} from 'src/services/interfaces/Shadow.types';
import { ErrorRes } from 'src/types';

export const ShadowService = (axios: AxiosInstance) => {
  const useGetShadowBySport = (payload: GetShadowBySportPayload) => {
    const getShadowBySport = () => axios
      .post(`${apiUrlV4}shadow/by-sport`, payload)
      .then((res) => {
        const resData: ShadowBySportRes = res.data;
        return resData;
      });

    return useQuery({
      queryKey: ['query-get-shadow-by-sport', payload],
      queryFn: getShadowBySport,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
      enabled: !!payload?.sport.length,
    });
  };

  const useGetShadow = () => {
    const getShadow = () => axios
      .get(`${apiUrlV4}shadow?sortBy=model&sort=asc`)
      .then((res) => {
        const noShadow = { value: null, label: 'None' };

        const mappedData = res.data.data.map((shadow: Shadow) => ({
          ...shadow,
          value: shadow._id,
          label: shadow.shadow,
        }));
        mappedData.unshift(noShadow);

        const resData: GetShadowRes = {
          data: mappedData,
          success: res.data.success,
        };

        return resData;
      });

    return useQuery({
      queryKey: ['get-shadow'],
      queryFn: getShadow,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
    });
  };

  return {
    useGetShadowBySport,
    useGetShadow,
  };
};
