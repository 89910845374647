import React from 'react';

import { Partner } from 'src/components/customer/schedules/components/regular-schedules/regular-schedule-form/utils/getPartners';
import { capitalize } from 'src/util/stringFormat';

type PartnersArray = {
  partnersArray: Partner[]
};

const PartnersMessageModal = ({ partnersArray }: PartnersArray) => {
  const partners = partnersArray.map((partner, index) => (
    <span
      key={partner.name}
    >
      <b>{capitalize(partner.name)}{index < partnersArray.length - 1
        ? ', '
        : ''}
      </b>
    </span>
  ));

  return (
    <>
      Changing time, date, or team data here will not be reflected in the {partners} system.
      If you need the changed data to also be reflected in {partners} system, we recommend you also make that edit in
      the {partners} system.
      If you only make the edit in the {partners} system, it will reflect in our system after ~1 hour.
    </>
  );
};

export default PartnersMessageModal;
