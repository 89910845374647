import dateFormat from 'src/util/dateFormat/dateFormat';
import { getFacility } from 'src/util/getFacility';

import { TBD_OPTION } from '../constants/constants';
import { FormatScheduleToEdit, ScheduleToEdit } from '../RegularScheduleForm.types';

export const formatScheduleToEdit = (schedule: ScheduleToEdit) => {
  if (!schedule?._id) {
    return null;
  }

  const {
    _id,
    game_start_ts,
    country,
    sport_data,
    gender_id,
    grade_id,

    facility_data,
    device_data,
    livestream,
    description,
    mapping,

    state,
    home_school_data,
    selected_home_association_id,

    home_team_id,
    home_team_name,

    away_state,
    away_school_data,
    selected_away_association_id,

    away_team_id,
    away_team_name,

    inactive,
    cancelled,
    postponed,
    facility_name,
    finalized,
    live,
    created_ts,
    facility_mongo_id,
    last_update_ts,
    tournament_id,
  } = schedule;

  const transformedSchedule: FormatScheduleToEdit = {
    _id,
    date: game_start_ts,
    country,
    sportId: sport_data?._id,
    gradeId: grade_id,
    genderId: gender_id,

    facility: facility_data?.length ? getFacility(facility_data[0]) : null,
    isDeviceOnline: device_data?.is_device_online,
    isLiveGame: device_data?.is_live,

    liveStreamURL: livestream,
    description,
    mapping,

    homeState: home_school_data?.state || state,
    homeAssociationId: selected_home_association_id,
    homeSchoolId: home_school_data?._id,
    homeTeamId: home_team_id,
    homeTeamOther: home_team_name || TBD_OPTION.label,

    awayState: away_school_data?.state || away_state,
    awayAssociationId: selected_away_association_id,
    awaySchoolId: away_school_data?._id,
    awayTeamId: away_team_id,
    awayTeamOther: away_team_name || TBD_OPTION.label,

    cancelled,
    inactive,
    postponed,
    finalized,
    live,
    created: dateFormat(created_ts, 'MM/DD/YYYY hh:mm'),
    updated: dateFormat(last_update_ts, 'MM/DD/YYYY hh:mm'),
    tournamentId: tournament_id,
  };

  if (!facility_mongo_id && facility_name) {
    Object.assign(transformedSchedule, { otherFacility: facility_name });
  }
  return transformedSchedule;
};
