import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CloseIcon from 'src/assets/images/menu-icons/close.svg';
import Button from 'src/components/common/button/CustomButton';
import { Input } from 'src/components/common/input/Input';
import { MessageModal } from 'src/components/common/modals/message-modal/MessageModal';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';
import confirmationModalInitialState
  from 'src/components/common/modals/temporary-confirmation-modal-new/confirmationModalInitianState';
import {
  TemporaryConfirmationModalNew,
} from 'src/components/common/modals/temporary-confirmation-modal-new/TemporaryConfirmationModalNew';
import {
  TemporaryConfirmationModalNewProps,
} from 'src/components/common/modals/temporary-confirmation-modal-new/TemporaryConfirmationModalNew.types';
import { SelectCountry } from 'src/components/common/select-country/SelectCountry';
import { MultiSelect, SelectInput } from 'src/components/common/select/Select';
import countries from 'src/constants/countries';
import statesByCountry from 'src/constants/statesByCountry';
import { ColorModalEnums } from 'src/enums/ColorModalEnums';
import api from 'src/services/api';
import ScorebirdService from 'src/services/scorebird.service';
import { RootState } from 'src/store/types/rootStateTypes';
import { Field, State } from 'src/types';
import { getCorrectLevelName } from 'src/util/getCorrectLevelName';

import { IForm, IAddTeamProps, OnChange } from './AddTeam.interface';
import styles from './AddTeam.module.css';
import { addTeamSchema } from './addTeamSchema';
import { generateName } from './addTeamUtils';

const { RED } = ColorModalEnums;

export const AddTeam = (props: IAddTeamProps) => {
  const {
    closeFn, team, addTeamData, addTeamFn,
  } = props;

  const sbs = useMemo(() => new ScorebirdService(), []);
  const {
    selectedCountry, selectedState, selectedSchool, currentUser,
  } = useSelector((state: RootState) => state.currentUser);

  const [
    {
      stateLoading, sportLoading, userLoading, levelLoading, genderLoading,
    },
    setIsLoading,
  ] = useState({
    stateLoading: false, sportLoading: false, userLoading: false, levelLoading: false, genderLoading: false,
  });
  const allSports = useSelector((store: RootState) => store.sports.sports);
  const allLevels = useSelector((store: RootState) => store.grades.grades);
  const [allGenders, setAllGenders] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [modal, setModal] = useState({ type: '', message: '', open: false });
  const [submitProcessing, setSubmitProcessing] = useState(false);
  const [isEdit, setIsEdit] = useState(true);

  const [confirmationModal, setConfirmationModal] = useState<TemporaryConfirmationModalNewProps>(() => confirmationModalInitialState);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    register,
    formState: { errors, isDirty },
  } = useForm<IForm>({
    resolver: yupResolver(addTeamSchema(currentUser?.super_admin)),
    mode: 'onChange',
    defaultValues: {
      name: '',
      country: null,
      state: null,
      school: null,
      sport: null,
      level: null,
      sublevel: '',
      gender: null,
      user: null,
      twitter_handle: '',
    },
  });

  const { useGetSchools } = api.schoolsService();
  const { useAddTeam } = api.teamsService();
  const { mutateAsync: addTeam } = useAddTeam();

  const getSchoolsPayload = {
    country: watch('country')?.value || currentUser.school_data[0]?.country,
    state: watch('state')?.value,
  };

  const { data: schools, isFetching: schoolLoading } = useGetSchools(getSchoolsPayload);

  const isAllSchoolsSelected = selectedSchool?.label === 'All Schools' || Array.isArray(selectedSchool?.value);

  useEffect(() => {
    // on Create if Super Admin
    if (!team && !addTeamData && currentUser?.super_admin) {
      setValue('country', selectedCountry || countries.find((c) => c.value === 'US'));
      setValue('state', selectedState);
      setValue('school', !isAllSchoolsSelected ? selectedSchool : null);
    }
  }, []);

  useEffect(() => {
    // on Create if not Super Admin
    if (!team && !addTeamData && !currentUser?.super_admin) {
      setValue('state', !isAllSchoolsSelected
        ? statesByCountry[selectedSchool?.country]?.find((s) => s.value === selectedSchool?.state)
        : selectedState);
      setValue('school', !isAllSchoolsSelected
        ? selectedSchool
        : null);
    }
  }, []);

  useEffect(() => {
    if (team) {
      setValue('country', countries.find((c) => c.value === (team?.country || team?.school_data?.country)));
      setValue('state', statesByCountry[team?.country || team?.school_data?.country]?.find((s) => s.value === team.school_data?.state));
    }
  }, []);

  useEffect(() => {
    if (isEdit && team && schools.data?.length) {
      setValue('school', schools.data.find((s) => s.value === team.school_id));
      setValue('sport', team?.sport_id ? allSports.find((sport) => sport.value === team.sport_id) : '');
      setValue('level', team?.level ? allLevels.find((level) => level.label === getCorrectLevelName(team.level)) : '');
      setValue('sublevel', team?.sublevel ? team?.sublevel : '');
      setValue('twitter_handle', team?.twitter_handle ? team?.twitter_handle : '');

      setIsEdit(false);
    }
  }, [schools.data?.length]);

  // Switch genders on level select
  useEffect(() => {
    setIsLoading((prevState) => ({ ...prevState, genderLoading: true }));
    const level = getValues('level');
    if (level && level.label.toLowerCase() === 'college') {
      setAllGenders([
        { label: "Men's", value: 'boys' },
        { label: "Women's", value: 'girls' },
      ]);
      setIsLoading((prevState) => ({ ...prevState, genderLoading: false }));
    } else {
      setAllGenders([
        { label: 'Boys', value: 'boys' },
        { label: 'Girls', value: 'girls' },
      ]);
      setIsLoading((prevState) => ({ ...prevState, genderLoading: false }));
    }
  }, [watch('level')]);

  // Getting users based on selected school
  useEffect(() => {
    setIsLoading((prevState) => ({ ...prevState, userLoading: true }));
    const school = getValues('school')?.value;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    school && sbs
      .getUsersBySchoolId(school)
      .then((res) => {
        const mappedUsers = res.data.map((u) => ({
          label: `${u.first_name} ${u.last_name} (${u.email})`,
          value: u._id,
        }));
        setAllUsers(mappedUsers);
        if (team) {
          setValue('user', mappedUsers.filter((u) => team.users?.includes(u.value)));
        }
      })
      .finally(() => {
        setIsLoading((prevState) => ({ ...prevState, userLoading: false }));
      });
  }, [watch('school')]);

  useEffect(() => {
    setValue('name', generateName({
      gender: getValues('gender'),
      level: getValues('level'),
      sport: getValues('sport'),
      sublevel: getValues('sublevel'),
    }));
  }, [watch('gender'), watch('level'), watch('sport'), watch('sublevel')]);

  // Set gender if we have team
  useEffect(() => {
    if (team) {
      setValue('gender', allGenders.find((g) => g.value === team.gender));
    }
  }, [allGenders]);

  useEffect(() => {
    if (addTeamData) {
      const { country, stateId } = addTeamData;

      setValue('country', countries.find((c) => c.value === country));
      setValue('state', statesByCountry[country]?.find((s) => s.value === stateId));
    }
  }, []);

  useEffect(() => {
    if (isEdit && addTeamData && schools.data?.length) {
      const {
        schoolId, sportId, genderLabel, levelId,
      } = addTeamData;

      setValue('school', schools.data?.find((school) => school.value === schoolId));
      setValue('sport', allSports.find((sport) => sport.value === sportId));
      setValue('gender', allGenders.find((gender) => gender.value === genderLabel));
      setValue('level', allLevels.find((level) => level.value === levelId));

      setIsEdit(false);
    }
  }, [schools.data?.length]);

  // processing validated fields
  const processSubmit = (data: IForm) => {

    setSubmitProcessing(true);
    const payload = {
      ...team,
      country: data.country?.value || (!currentUser?.super_admin && currentUser?.country),
      state: data.state.value,
      school_id: data.school.value,
      school: `${data.school.label}, ${data.state.value}`, // NO NEED
      school_mongo_id: data.school.value,
      name: data.name,
      sport: data.sport.value,
      gender: data.gender.value,
      level: data.level.label.toLowerCase(),
      sublevel: data.sublevel,
      users: data.user.map((u) => u.value),
      twitter_handle: data.twitter_handle,
    };

    if (team) {
      sbs.updateTeamInfo(payload).then((res) => {
        if (res.success) {
          setModal({ type: 'success', message: 'Team successfully saved! ', open: true });
          setTimeout(() => {
            window.location.reload();
          }, 1300);
        } else {
          setModal({ type: 'error', message: 'Error on saving team, please retry!', open: true });
        }
      }).finally(() => {
        setSubmitProcessing(false);
      });
    } else {
      addTeam(payload).then((res) => {
        if (res.success) {
          if (addTeamFn) {
            addTeamFn(addTeamData.type);
            return;
          }
          setModal({ type: 'success', message: 'Successfully added new team!', open: true });
          setTimeout(() => {
            window.location.reload();
          }, 1300);
        } else {
          setModal({ type: 'error', message: 'Error on adding team, please retry!', open: true });
        }
      }).finally(() => {
        setSubmitProcessing(false);
      });
    }
  };

  const handleCountryChange = (value: Field, onChange: OnChange) => {
    onChange(value);
    setValue('state', null);
    setValue('school', null);
  };

  const handleStateChange = (value: State, onChange: OnChange) => {
    onChange(value);
    setValue('school', null);
  };

  const handleSchoolChange = (value, onChange: OnChange) => {
    onChange(value);
    setValue('school', value, { shouldValidate: true });
  };

  const handleCloseModal = () => {
    if (isDirty) {
      setConfirmationModal({
        ...confirmationModal,
        open: true,
        type: RED,
        title: 'Are you sure you want to exit? Your\n changes will not be saved.',
        approveFn: () => closeFn(),
        cancelFn: () => setConfirmationModal(confirmationModalInitialState),
      });

      return;
    }

    closeFn();
  };

  return (
    <ModalWindow isOpen className={styles.modal}>
      <Button
        version="icon"
        onClick={handleCloseModal}
        className={styles.closeBtn}
      >
        <img src={CloseIcon} alt="close" />
      </Button>
      <div className={styles.header}>{team ? 'Edit Team' : 'Add Team'}</div>
      <div className={styles.rowTeam}>
        <input
          {...register('name')}
          className={styles.teamName}
          placeholder="Name"
          type="text"
          readOnly
        />
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          {currentUser.super_admin && (
            <Controller
              control={control}
              name="country"
              render={({
                field: {
                  value, onChange, ref, onBlur,
                },
                fieldState: { error },
              }) => (
                <SelectCountry
                  isLabel
                  value={value}
                  onChange={(selected) => handleCountryChange(selected, onChange)}
                  inputRef={ref}
                  onBlur={onBlur}
                  isDisabled={!!team}
                  errorMsg={error?.message}
                />
              )}
            />
          )}
          <Controller
            name="state"
            control={control}
            render={({
              field: {
                value, onChange, ref, onBlur,
              },
              fieldState: { error },
            }) => (
              <SelectInput
                options={statesByCountry[watch('country')?.value || currentUser.school_data[0]?.country]}
                label="State"
                placeholder="Select State"
                value={value}
                onChange={(selected) => handleStateChange(selected, onChange)}
                inputRef={ref}
                onBlur={onBlur}
                isLoading={stateLoading}
                menuPlacement="bottom"
                errorMsg={error?.message}
              />
            )}
          />
          <Controller
            name="school"
            control={control}
            render={({
              field: {
                value, onChange, ref, onBlur,
              },
              fieldState: { error },
            }) => (
              <SelectInput
                options={schools?.data}
                label="School"
                placeholder="Select School"
                value={value}
                onChange={(selected) => handleSchoolChange(selected, onChange)}
                inputRef={ref}
                onBlur={onBlur}
                isLoading={schoolLoading}
                menuPlacement="bottom"
                errorMsg={error?.message}
              />
            )}
          />
          <Controller
            name="sport"
            control={control}
            render={({
              field: {
                value, onChange, ref, onBlur,
              },
              fieldState: { error },
            }) => (
              <SelectInput
                options={allSports}
                label="Sport"
                placeholder="Select Sport"
                isLoading={sportLoading}
                value={value}
                onChange={onChange}
                inputRef={ref}
                onBlur={onBlur}
                errorMsg={error?.message}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({
              field: {
                value, onChange, ref, onBlur,
              },
              fieldState: { error },
            }) => (
              <SelectInput
                options={allGenders}
                label="Gender"
                placeholder="Select Gender"
                isLoading={genderLoading}
                value={value}
                onChange={onChange}
                inputRef={ref}
                onBlur={onBlur}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className={styles.col}>
          <Controller
            name="level"
            control={control}
            render={({
              field: {
                value, onChange, ref, onBlur,
              },
              fieldState: { error },
            }) => (
              <SelectInput
                options={allLevels}
                label="Level"
                placeholder="Select Level"
                isLoading={levelLoading}
                value={value}
                onChange={onChange}
                inputRef={ref}
                onBlur={onBlur}
                menuPlacement="bottom"
                errorMsg={error?.message}
              />
            )}
          />
          <Controller
            name="sublevel"
            control={control}
            render={({
              field: {
                value, onChange, ref, onBlur,
              },
              fieldState: { error },
            }) => (
              <Input
                name="sublevel"
                label="Sub-Level"
                placeholder="Enter Sub-Level"
                value={value}
                onChange={onChange}
                inputRef={ref}
                onBlur={onBlur}
                errorMsg={error?.message}
              />
            )}
          />
          <Controller
            name="user"
            control={control}
            render={({
              field: {
                value, onChange, ref, onBlur,
              },
              fieldState: { error },
            }) => (
              <MultiSelect
                options={allUsers}
                value={value}
                onChange={onChange}
                inputRef={ref}
                onBlur={onBlur}
                label="Users"
                placeholder="Select Users"
                isLoading={userLoading}
                errorMsg={error?.message}
              />
            )}
          />
          <Controller
            name="twitter_handle"
            control={control}
            render={({
              field: {
                value, onChange, ref, onBlur,
              },
              fieldState: { error },
            }) => (
              <Input
                label="Twitter Handle (Optional)"
                placeholder="Enter Twitter Handle"
                value={value}
                onChange={onChange}
                inputRef={ref}
                onBlur={onBlur}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
      </div>

      <div className={styles.actions}>
        <Button
          className={styles.cancelBtn}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className={styles.addBtn}
          disabled={submitProcessing || Object.keys(errors).length > 0}
          onClick={handleSubmit(processSubmit)}
        >
          {submitProcessing ? 'Submitting...' : team ? 'Save' : 'Add'}
        </Button>
      </div>
      {modal.open && (
        <MessageModal
          type={modal.type}
          message={modal.message}
          closeFn={() => {
            setModal({ type: '', message: '', open: false });
            if (modal.type.toLowerCase() === 'success') {
              closeFn();
            }
          }}
        />
      )}

      {confirmationModal.open && (
        <TemporaryConfirmationModalNew
          type={confirmationModal.type}
          title={confirmationModal.title}
          message={confirmationModal.message}
          approveFn={confirmationModal.approveFn}
          closeFn={confirmationModal.closeFn}
          cancelFn={confirmationModal.cancelFn}
          approveTitle={confirmationModal.approveTitle}
          cancelTitle={confirmationModal.cancelTitle}
          hideCancelBtn={confirmationModal.hideCancelBtn}
        />
      )}
    </ModalWindow>
  );
};
