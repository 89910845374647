/* Known issue - braking buttons css of imports is rearranged */
/* Bad css configurations */
import './App.scss';
import React, { Suspense, useEffect, useState } from 'react';

import { onAuthStateChanged } from 'firebase/auth';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Spinner from 'src/components/common/spinner/Spinner';
import { SocketProvider } from 'src/context/socket';

import { NetworkError } from './components/common/other-pages/NetworkError';
import { Snackbar } from './components/common/snackbar/Snackbar';
import { firebaseAuth } from './fb-configuration/db-firebase';
import { ScoreBirdRouter } from './router/ScoreBirdRouter';
import { getIdToken } from './util/getIdToken';

const App = () => {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);

  /* For mobile app ----------------------------------------------------------*/
  useEffect(() => {
    const url = new URL(window.location.href.replace('/#', ''));
    const fromMobile = new URLSearchParams(url.search).get('deepLink');

    if (fromMobile) {
      const platform = /iPhone|iPad|iPod/.test(navigator.userAgent)
        ? 'Apple'
        : /Android/.test(navigator.userAgent)
          ? 'Android'
          : 'Other';
      switch (platform) {
        case 'Apple':
          // @ts-ignore
          window.location = `scorebirdmanagementapp://${fromMobile}`;
          setTimeout(() => {
            window.location.href = 'https://itunes.apple.com/us/app/scorebirdmanagementapp/id1472227801?ls=1&mt=8';
          }, 1000);
          break;
        case 'Android':
          // @ts-ignore
          window.location = `scorebirdmanagementapp://${fromMobile}`;
          setTimeout(() => {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.scorebird.customermanagement';
          }, 1000);
          break;
        default:
          break;
      }
    }
  }, []);
  /*--------------------------------------------------------------------------*/

  const handleReload = () => {
    if (navigator.onLine) {
      window.location.reload();
    }
  };

  function updateOnlineStatus() {
    const networkError = document.getElementById('network');
    const app = document.getElementById('app');
    app.style.display = 'none';
    networkError.style.display = 'block';
  }

  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);

  useEffect(() => {
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = 'cabdde75-39e1-41e6-bb00-c4e2f4fda2f5';
    ((() => {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = Boolean(1); d.getElementsByTagName('head')[0].appendChild(s);
    })());
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (userData) => {
      if (userData) {
        getIdToken().then((idToken) => {
          if (idToken !== null) {
            dispatch({
              type: 'currentUser/setUserEmail',
              payload: userData.email,
            });
            dispatch({
              type: 'token/set',
              payload: idToken,
            });
            setIsAuth(true);
            setAuthLoaded(true);
          }
        });
      } else {
        setIsAuth(false);
        setAuthLoaded(true);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SocketProvider>
        <div id="app" style={{ display: navigator.onLine ? 'block' : 'none' }}>
          <SnackbarProvider>
            <BrowserRouter>
              <Snackbar />
              <Suspense fallback={<div className="loader"><Spinner /></div>}>
                {
                authLoaded && <ScoreBirdRouter isAuth={isAuth} />
              }
              </Suspense>
            </BrowserRouter>
          </SnackbarProvider>
        </div>
        <div id="network" style={{ display: navigator.onLine ? 'none' : 'block' }}>
          <NetworkError onReload={handleReload} />
        </div>
      </SocketProvider>
    </QueryClientProvider>
  );
};

export default App;
