import React from 'react';

import { PartnerEnums } from 'src//enums/PartnerEnums';

import { FormatScheduleToEdit } from '../RegularScheduleForm.types';

const { RANKONE } = PartnerEnums;

const statusConditions = [
  { key: 'finalized', status: 'Finalized', color: 'green' },
  { key: 'inactive', status: 'Inactive', color: 'red' },
  { key: 'cancelled', status: 'Canceled', color: 'red' },
  { key: 'postponed', status: 'Postponed', color: 'red' },
  { key: 'rankone', status: 'RankOne', color: 'green' },
];

export const getScheduleStatus = (schedule: FormatScheduleToEdit) => {
  if (!schedule) return '';

  const findRankOneSchedule = schedule.mapping?.find((s) => s.name === RANKONE);
  const { status, color } = statusConditions.find(({ key }) => schedule[key] || (key === findRankOneSchedule?.name)) || {};

  return status ? <span style={{ color }}>{`(${status})`}</span> : '';
};
