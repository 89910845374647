import {
  countrySchema,
  stateSchema,
  schoolSchema,
  roleSchema,
  subLevelSchema,
  genderSchema,
  sportSchema,
  usersSchema,
  twitterHandleSchema,
} from 'src/schemas';
import { object, string } from 'yup';

export const addTeamSchema = (isSuperAdmin) => {
  return object().shape({
    country: isSuperAdmin ? countrySchema : object().nullable().notRequired(),
    state: stateSchema,
    school: schoolSchema,
    sport: sportSchema,
    level: roleSchema,
    sublevel: subLevelSchema,
    gender: genderSchema,
    user: usersSchema,
    twitter_handle: twitterHandleSchema,
    name: string()
      .nullable(), // for handling null value when clearing options via clicking "x",
  });
};
