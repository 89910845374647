import React from 'react';

import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import DoubleLeftIcon from 'src/assets/images/calendar/arrow-back-double-left-icon.svg';
import DoubleRightIcon from 'src/assets/images/calendar/arrow-back-double-right-icon.svg';
import LeftIcon from 'src/assets/images/calendar/arrow-back-left-icon.svg';
import RightIcon from 'src/assets/images/calendar/arrow-back-right-icon.svg';
import { months } from 'src/constants';

import styles from './Header.module.css';

const Header = ({
  date,
  decreaseYear,
  increaseYear,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) => {

  const today = new Date(date);
  const month = months[today.getMonth()];
  const year = today.getFullYear();

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.btnGroup}>
        <button className={styles.btn} onClick={decreaseYear}>
          <img src={DoubleLeftIcon} alt="prev-year-icon" />
        </button>

        <button className={styles.btn} onClick={decreaseMonth}>
          <img src={LeftIcon} alt="prev-month-icon" />
        </button>
      </div>

      <span className={styles.date}>{month} {year}</span>

      <div className={styles.btnGroup}>
        <button className={styles.btn} onClick={increaseMonth}>
          <img src={RightIcon} alt="next-month-icon" />
        </button>

        <button className={styles.btn} onClick={increaseYear}>
          <img src={DoubleRightIcon} alt="next-year-icon" />
        </button>
      </div>
    </div>
  );
};

export default Header;
