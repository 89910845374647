import { OTHER_OPTION } from '../constants/constants';

export const getCompetitorName = (school, team, teamName = '') => {
  if (school && school.value) {
    return school.display_name ? school.display_name : school.label;
  } if (team && team.value === OTHER_OPTION.value) {
    return teamName;
  }
  return team && team.value ? team.label : '';
};
