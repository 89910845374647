import { useEffect } from 'react';

import countries from 'src/constants/countries';
import { SetValue } from 'src/types';

import { RegularScheduleField, RegularScheduleFormData, State } from '../RegularScheduleForm.types';

type SelectedSchool = RegularScheduleField & { associationId: string, _id: string };
type Association = { value: string, label: JSX.Element };

type PreFill = {
  scheduleId: string,
  isSuperAdmin: boolean,
  isAssociationAdmin: boolean,
  setValue: SetValue<RegularScheduleFormData>,
  selectedCountry: RegularScheduleField,
  selectedState: State,
  selectedAssociation: { data: { _id: string } },
  selectedSchool: SelectedSchool,
  transformedAssociationsList: Association[],
  isAllSchoolsSelected: boolean,
  currentUserCountry: string,
  preFilledState: State,
  preFilledSchool: SelectedSchool,
  preFilledAssociation: Association,
  areMultipleStates: boolean,
};

const usePreFill = ({
  scheduleId,
  isSuperAdmin,
  isAssociationAdmin,
  setValue,
  selectedCountry,
  selectedState,
  selectedAssociation,
  selectedSchool,
  transformedAssociationsList,
  isAllSchoolsSelected,
  currentUserCountry,
  preFilledState,
  preFilledSchool,
  preFilledAssociation,
  areMultipleStates,
}: PreFill) => {

  useEffect(() => {
    if (scheduleId) return;

    if (isSuperAdmin) {
      setValue('country', selectedCountry || countries.find((c) => c.value === currentUserCountry));
      setValue('homeState', selectedState);
      setValue('homeAssociation', selectedState && transformedAssociationsList?.find((association) => association.value === selectedAssociation?.data._id));
      setValue('homeSchool', !isAllSchoolsSelected && selectedSchool);

      return;
    }

    if (isAllSchoolsSelected && areMultipleStates && !isAssociationAdmin) {
      return;
    }

    setValue('homeState', preFilledState);
    setValue('homeAssociation', preFilledAssociation);
    setValue('homeSchool', !isAllSchoolsSelected && { label: preFilledSchool.name, value: preFilledSchool._id, display_name: preFilledSchool.display_name });
    setValue('awayState', isAssociationAdmin && preFilledState);
  }, []);
};

export default usePreFill;
