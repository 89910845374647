import { RegularScheduleField } from 'src/components/customer/schedules/components/regular-schedules/regular-schedule-form/RegularScheduleForm.types';

export const getGenderOptions = (genders: RegularScheduleField[], level: string) => {
  // College
  if (level === '623de6ee105b824288914501' || level === '613f823478f16d522777608b') {
    return genders.map((gender) => ({
      ...gender,
      label: gender.label === 'Boys' ? "Men's" : "Women's",
    }));
  }
  // Other case
  return genders;
};
