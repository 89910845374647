type TBDOption = {
  label: 'TBD'
  value: 'tbd',
};

type OtherOption = {
  label: 'Other',
  value: 'other',
};

export const TBD_OPTION: TBDOption = {
  label: 'TBD',
  value: 'tbd',
};

export const OTHER_OPTION: OtherOption = {
  label: 'Other',
  value: 'other',
};
