import React from 'react';

import cn from 'classnames';
import { Controller } from 'react-hook-form';
import PlusIcon from 'src/assets/images/add-blue-plus-icon.svg';
import Button from 'src/components/common/button/CustomButton';
import { Input } from 'src/components/common/input/Input';
import { SelectInput } from 'src/components/common/select/Select';
import statesByCountry from 'src/constants/statesByCountry';
import { TeamEnums } from 'src/enums/TeamEnums';
import { capitalize } from 'src/util/stringFormat';

import styles from './Team.module.css';
import { TeamProps } from './Team.types';

const { HOME } = TeamEnums;

const Team = (props: TeamProps) => {
  const {
    type,
    control,
    isSuperAdmin,
    currentUserCountry,
    watchCountryValue,
    isAssociationAdmin,
    associationOptions,
    schoolOptions,
    teamOptions,
    isSchoolOptionsLoading,
    isTeamOptionsLoading,
    isTeamOther,
    showCreateAutomatically,
    isTeamError,
    isDisabled,
    isSchoolDisabled,
    isTeamDisabled,
    handleStateChange,
    handleAssociationChange,
    handleSchoolChange,
    handleAddTeamClick,
  } = props;

  return (
    <div className={styles.scheduleFormColumn}>
      <h6 className={styles.columnTitle}>{type === HOME ? 'Home' : 'Away'} Team</h6>
      <Controller
        control={control}
        name={`${type}State`}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectInput
            label={`${capitalize(type)} State`}
            placeholder={`Select ${capitalize(type)} State`}
            options={statesByCountry[watchCountryValue || (!isSuperAdmin && currentUserCountry)]}
            value={value}
            onChange={(selected) => handleStateChange(selected, type, onChange)}
            errorMsg={error?.message}
            isDisabled={isDisabled}
          />
        )}
      />

      {(isAssociationAdmin || isSuperAdmin) && (
        <Controller
          control={control}
          name={`${type}Association`}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectInput
              label={`${capitalize(type)} Association`}
              placeholder={`Select ${capitalize(type)} Association`}
              options={associationOptions}
              value={value}
              onChange={(selected) => handleAssociationChange(selected, type, onChange)}
              errorMsg={error?.message}
              isDisabled={isDisabled}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name={`${type}School`}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectInput
            label={`${capitalize(type)} School`}
            placeholder={`Select ${capitalize(type)} School`}
            options={schoolOptions}
            value={value}
            onChange={(selected) => handleSchoolChange(selected, type, onChange)}
            isLoading={isSchoolOptionsLoading}
            errorMsg={error?.message}
            isDisabled={isDisabled || isSchoolDisabled}
          />
        )}
      />
      <div>

        <Controller
          control={control}
          name={`${type}Team`}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectInput
              label={`${capitalize(type)} Team`}
              placeholder={`Select ${capitalize(type)} Team`}
              options={teamOptions}
              value={value}
              onChange={onChange}
              isLoading={isTeamOptionsLoading}
              errorMsg={error?.message}
              isDisabled={isDisabled || isTeamDisabled}
            />
          )}
        />

        {showCreateAutomatically && (
          <Button
            className={cn(styles.addTeamBtn, isTeamError && styles.addTeamError)}
            version="icon"
            onClick={() => handleAddTeamClick(type)}
          >
            <img
              className={styles.addTeamIcon}
              src={PlusIcon}
              alt={`create-automatically-${capitalize(type)}-team`}
            />
            Create Team
          </Button>
        )}

      </div>

      {isTeamOther && (
        <div className={styles.otherTeam}>
          <Controller
            control={control}
            name={`${type}TeamOther`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                type="text"
                placeholder={`Enter Other ${capitalize(type)} Team`}
                label={`Other ${capitalize(type)} Team`}
                name={`${type}TeamOther`}
                value={value}
                onChange={onChange}
                errorMsg={error?.message}
                disabled={isDisabled}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Team;
