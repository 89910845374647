import React from 'react';

import {
  useRoutes, Navigate,
} from 'react-router-dom';

import { useRouteConfig } from './useRouteConfig';

export const ScoreBirdRouter = (props: { isAuth: boolean }) => {
  const { isAuth } = props;
  const routes = useRouteConfig();

  return useRoutes(
    routes.map((route) => ({
      path: route.path,
      element: isAuth || !route.protected ? route.element : <Navigate to="/sign-in" replace />,
    })),
  );
};
