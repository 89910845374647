import React from 'react';

import { Controller } from 'react-hook-form';
import { DatePickerInput } from 'src/components/common/date-picker/DatePickerInput';
import { SelectCountry } from 'src/components/common/select-country/SelectCountry';
import { SelectInput } from 'src/components/common/select/Select';

import styles from './GeneralInformation.module.css';
import { GeneralInformationProps } from './GeneralInformation.types';
import { getGenderOptions } from './utils/getGenderOptions';

const GeneralInformation = (props: GeneralInformationProps) => {
  const {
    control,
    sportOptions,
    gradeOptions,
    genderOptions,
    pitchCountScheduleDate,
    watchLevelValue,
    isSuperAdmin,
    isEdit,
    isDisabled,
    handleCountryChange,
  } = props;

  return (
    <div className={styles.scheduleFormColumn}>
      <h6 className={styles.columnTitle}>General Information</h6>

      <div className={styles.dateWrapper}>
        <Controller
          control={control}
          name="date"
          render={({ field: { value, onChange } }) => (
            <DatePickerInput
              popperClassName={styles.datePickerPopper}
              label="Date"
              placeholder="Choose Start Date"
              pitchCountScheduleDate={pitchCountScheduleDate}
              value={value}
              onChange={onChange}
              timePicker
              dateFormat="M/dd/yyyy, h:mm aa"
              isDisabled={isDisabled}
            />
          )}
        />
      </div>

      {isSuperAdmin && (
        <Controller
          control={control}
          name="country"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectCountry
              isLabel
              value={value}
              onChange={(selected) => handleCountryChange(selected, onChange)}
              isDisabled={!!isEdit}
              errorMsg={error?.message}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="sport"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectInput
            label="Sport"
            placeholder="Select Sport"
            options={sportOptions}
            value={value}
            onChange={onChange}
            errorMsg={error?.message}
            isDisabled={isDisabled}
          />
        )}
      />

      <Controller
        control={control}
        name="level"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectInput
            label="Level"
            placeholder="Select Level"
            options={gradeOptions}
            value={value}
            onChange={onChange}
            errorMsg={error?.message}
            isDisabled={isDisabled}
          />
        )}
      />

      <Controller
        control={control}
        name="gender"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectInput
            label="Gender"
            placeholder="Select Gender"
            options={getGenderOptions(genderOptions, watchLevelValue)}
            value={value}
            onChange={onChange}
            errorMsg={error?.message}
            isDisabled={isDisabled}
          />
        )}
      />
    </div>
  );
};

export default GeneralInformation;
