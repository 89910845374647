import { useSelector } from 'react-redux';
import { SchoolEnums } from 'src/enums/SchoolEnums';
import { RootState } from 'src/store/types/rootStateTypes';

const { ALL_SCHOOLS } = SchoolEnums;

const useAllSchoolsSelected = () => {
  const { selectedSchool } = useSelector((state: RootState) => state.currentUser);

  const isAllSchoolsSelected = selectedSchool?.label === ALL_SCHOOLS || Array.isArray(selectedSchool?.value);

  return { isAllSchoolsSelected };
};

export default useAllSchoolsSelected;
